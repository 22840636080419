<template>
  <div id="ind-index">
    <div class="header">
      <img src="@/assets/in-header.png">
    </div>
    <div class="content">

      <div class="chart-info">
        <div class="chart-data">
          <span style="color: #0089d0; font-weight: 600; font-size: 110%;">BSE SENSEX</span>
          <span style="color: #0f486a; font-size: 130%; font-weight: bold; margin-left: 10px;">{{ dispChartData('price') | dispPrice }}</span>
          <span class="d" :class="[isPriceUp?'up':'down']" style="margin-left: 5px;">{{isPriceUp?'+':''}}{{ dispChartData('diff') }}</span>
          <span class="p" :class="[isPriceUp?'up':'down']" style="margin-left: 5px;">{{isPriceUp?'+':''}}{{ dispChartData('per') }}%</span>
        </div>
        <div class="chart-date">
          {{ chartDate }} | {{ chartTime }} (GMT+5:30) |
          <strong v-if="isOpen" class="is-open">Open</strong>
          <strong v-else class="is-close">Close</strong>
        </div>
      </div>

      <apexchart width="100%" height="280" ref="inChart" :options="options" :series="series"></apexchart>

      <div class="today-result">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>close</td>
              <td>top</td>
              <td><strong>{{ r2ResultThree }}</strong></td>
              <td>bottom</td>
              <td><strong>{{ r2ResultTwo }}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="other-index">
        <ul>
          <li class="active"><a id="index" href="#indi">Indices</a></li>
          <li><a id="gainer" href="#gain">Gainers</a></li>
          <li><a id="loser" href="#lose">Losers</a></li>
          <li><a id="tt" href="#tturn">Top T/O </a></li>
          <li><a id="bd" href="#blockd">Block Deals</a></li>
          <li><a href="#trend">Trending</a></li>
          <li><a id="listing" href="#list">Listings</a></li>
          <li><a id="ann" href="#lat1">Corp Ann</a></li>
          <li><a id="ipo" href="#homeipo">Public Issues</a></li>
        </ul>

        <table class="table table-striped mb-0">
          <thead>
            <tr>
              <th class="text-left">Index</th>
              <th class="text-right" width="20%">Current</th>
              <th class="text-right" width="20%">Pt. Change</th>
              <th class="text-right" width="20%">% Change</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-left" style="color: #0089d0;">BSE SENSEX</td>
              <td class="text-right">{{ dispChartData('price') | dispPrice }}</td>
              <td :class="[isPriceUp?'up':'down']" class="text-right">{{isPriceUp?'+':''}}{{ dispChartData('diff') }}</td>
              <td :class="[isPriceUp?'up':'down']" class="text-right">{{isPriceUp?'+':''}}{{ dispChartData('per') }}%</td>
            </tr>
            <tr>
              <td class="text-left" style="color: #0089d0;">BSE BANKEX</td>
              <td class="text-right">{{ otherIndexData('bseBankex', 'price') | dispPrice }}</td>
              <td :class="[isOtherUp('bseBankex')?'up':'down']" class="text-right">{{isOtherUp('bseBankex')?'+':''}}{{ otherIndexData('bseBankex', 'diff') }}</td>
              <td :class="[isOtherUp('bseBankex')?'up':'down']" class="text-right">{{isOtherUp('bseBankex')?'+':''}}{{ otherIndexData('bseBankex', 'per') }}</td>
            </tr>
            <tr>
              <td class="text-left" style="color: #0089d0;">BSE SENSEX 50</td>
              <td class="text-right">{{ otherIndexData('bseSensex50', 'price') | dispPrice }}</td>
              <td :class="[isOtherUp('bseSensex50')?'up':'down']" class="text-right">{{isOtherUp('bseSensex50')?'+':''}}{{ otherIndexData('bseSensex50', 'diff') }}</td>
              <td :class="[isOtherUp('bseSensex50')?'up':'down']" class="text-right">{{isOtherUp('bseSensex50')?'+':''}}{{ otherIndexData('bseSensex50', 'per') }}</td>
            </tr>
            <tr>
              <td class="text-left" style="color: #0089d0;">BSE 100</td>
              <td class="text-right">{{ otherIndexData('bse100', 'price') | dispPrice }}</td>
              <td :class="[isOtherUp('bse100')?'up':'down']" class="text-right">{{isOtherUp('bse100')?'+':''}}{{ otherIndexData('bse100', 'diff') }}</td>
              <td :class="[isOtherUp('bse100')?'up':'down']" class="text-right">{{isOtherUp('bse100')?'+':''}}{{ otherIndexData('bse100', 'per') }}</td>
            </tr>
            <tr>
              <td class="text-left" style="color: #0089d0;">BSE Bharat 22 Index</td>
              <td class="text-right">{{ otherIndexData('bseBharat22', 'price') | dispPrice }}</td>
              <td :class="[isOtherUp('bseBharat22')?'up':'down']" class="text-right">{{isOtherUp('bseBharat22')?'+':''}}{{ otherIndexData('bseBharat22', 'diff') }}</td>
              <td :class="[isOtherUp('bseBharat22')?'up':'down']" class="text-right">{{isOtherUp('bseBharat22')?'+':''}}{{ otherIndexData('bseBharat22', 'per') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="banner">
      <img src="@/assets/ind-banner.png">
    </div>

    <div class="text-center pt-1">
      <a href="https://indiastock-vip.com/" target="_blank">www.indiastock-vip.com</a>
    </div>
    <div class="in-final-footer"></div>
  </div>
</template>
<script>
import moment from '@/moment.js'
import LottoService from '@/services/lottoService'
import VueApexCharts from 'vue-apexcharts'

let constChartDate = moment().format("YYYY-MM-DD")

export default {
  name: 'InIndex',
  components: {
    'apexchart': VueApexCharts
  },
  data() {
    return {
      Date: null,
      Prices: [],
      labelTimes: [],
      options: {
        chart: {
          id: 'ind-index-chart',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          floating: true
        },
        subtitle: {
          text: '',
          floating: true
        },
        stroke: {
          curve: 'straight', // smooth | straight
          width: 3
        },
        xaxis: {
          type: 'category',
          labels: {
            show: true,
            rotate: 0,
            offsetX: 0,
            offsetY: 0,
            style: {
              colors: '#000'
            },
            formatter: (value)=>{
              switch(value) {
                case '10:00':
                  return '10:00'
                case '11:00':
                  return '11:00'
                case '12:00':
                  return '12:00'
                case '13:00':
                  return '13:00'
                case '14:00':
                  return '14:00'
                case '15:00':
                  return '15:00'
                case '16:00':
                  return '16:00'
                default:
                  return ''
              }
            }
          },
          axisTicks: {
            show: false,
            color: '#000'
          }
        },
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            style: {
              colors: '#000'
            },
            formatter: function(val) {
              return val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          },
          axisTicks: {
            show: false,
            color: '#000',
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 5,
        },
        colors: ['#5FA7C9'],
        grid: {
          show: true,
          borderColor: '#EDEDED',
          position: 'back',
          yaxis: {
            lines: {
              show: true
            }
          },
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '12px'
          },
          x: {
            formatter: function(val) {
              return constChartDate ? moment(`${constChartDate} 10:00`).add(val-1, 'm').format('ddd MMM DD HH:mm') + ' GMT+5:30' : null
            }
          },
          y: {
            formatter: function(val) {
              return val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
          }
        },
        legend: {
          show: false
        },
        annotations: {
          position: 'back',
          xaxis: [
            {
              x: '10:00',
              borderColor: '#EDEDED',
              strokeDashArray: 0
            },
            {
              x: '11:00',
              borderColor: '#EDEDED',
              strokeDashArray: 0
            },
            {
              x: '12:00',
              borderColor: '#EDEDED',
              strokeDashArray: 0
            },
            {
              x: '13:00',
              borderColor: '#EDEDED',
              strokeDashArray: 0
            },
            {
              x: '14:00',
              borderColor: '#EDEDED',
              strokeDashArray: 0
            },
            {
              x: '15:00',
              borderColor: '#EDEDED',
              strokeDashArray: 0
            },
            {
              x: '16:00',
              borderColor: '#EDEDED',
              strokeDashArray: 0
            }
          ]
        }
      },
      series: [
        {
          name: 'Value',
          type: 'area',
          data: []
        }
      ],
      StartTime: null,
      EndTime: null,
      cLabels: null,
      todayResult: {
        r2: null
      }
    }
  },
  computed: {
    lastPrice() {
      return this.Prices[this.Prices.length - 1]
    },
    chartDate() {
      if(this.Prices) {
        return moment(this.Date).format("DD MMM YY")
      }else{
        return null
      }
    },
    chartTime() {
      return this.lastPrice?.time ?? '-'
    },
    isPriceUp() {
      if(this.lastPrice) {
        if(parseFloat(this.lastPrice.diff) >= 0) {
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    },
    isOpen() {
      return this.lastPrice?.time && this.lastPrice?.time !== '16:15'
    },
    r2ResultThree() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultTwo() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    }
  },
  methods: {
    dispChartData(key) {
      if(this.lastPrice) {
        return Number(this.lastPrice[key]) === this.lastPrice[key] ? this.lastPrice[key].toFixed(2) : this.lastPrice[key]
      }else{
        return ''
      }
    },
    updateChart() {
      LottoService
        .getInData()
        .then((response)=>{
          if(response.status === 'success') {

            constChartDate = response.data.date
            this.Date = response.data.date
            this.Prices = response.data.prices
            this.labelTimes = response.data.tLabels
            this.StartTime = response.data.tLabels[0]
            this.EndTime = response.data.tLabels[response.data.tLabels.length - 1]
            this.cLabels = response.data.labels

            const self = this
            this.$refs.inChart.updateOptions({
              ...this.options,
              xaxis: {
                ...this.options.xaxis,
                labels: {
                  ...this.options.xaxis.labels,
                  formatter: (value)=>{
                    if(self.cLabels[value])
                      return self.cLabels[value]
                    else
                      return ''
                  }
                }
              }
            })

            let prevPrice = null
            this.$refs.inChart.updateSeries([{
              name: 'Value',
              data: this.labelTimes.map((label)=>{

                let price = response.data.prices.find((p)=>{
                  return p.time === label
                })

                if(price) {
                  prevPrice = parseFloat(price.price)
                  return {
                    x: label,
                    y: parseFloat(price.price)
                  }
                }else
                if(moment().add(-90, 'm').isAfter(moment(`${constChartDate} ${label}`))){
                  return {
                    x: label,
                    y: prevPrice
                  }
                }else{
                  return {
                    x: label,
                    y: null
                  }
                }

              })
            }])

            // หาผลของวันนี้
            let r2 = this.Prices.find((p)=>{
              return p.time === '16:15' && p.available === true
            })
            if(r2 !== undefined) {
              this.todayResult.r2 = r2
            }

          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {
      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log('#1', this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().add(-90, 'm').isBetween(moment(`${this.Date} ${this.StartTime}`), moment(`${this.Date} ${this.EndTime}`).add(15, 'm')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log('#2', this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log('reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }
    },
    otherIndexData(index, key) {
      return this.lastPrice?.[index]?.[key] ?? ''
    },
    isOtherUp(index) {
      if(this.lastPrice) {
        if(parseFloat(this.lastPrice?.[index]?.diff ?? '0') >= 0) {
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    }
  },
  mounted() {
    this.updateChart()
  },
  filters: {
    dispPrice(price) {
      return price.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
</script>
<style lang="scss" scoped>
#ind-index {
  margin-bottom: 0px;

  .header {
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      min-width: 600px;
    }
  }

  .content {
    background-color: #FFFFFF;
    padding: 15px;

    .down {
      color: #e60000;
    }

    .up {
      color: #179600;
    }

    .chart-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .chart-data {
        display: flex;
        align-items: center;
        padding-left: 15px;
        font-weight: bold;
      }

      .chart-date {
        font-size: 90%;

        .is-close {
          color: #e60000;
        }

        .is-open {
          color: #179600;
        }
      }
    }

    .today-result {
      .table {
        margin: 0 auto;
        width: 500px;
        max-width: 100%;
      }
      .table>tbody>tr>td {
        padding: 5px;
        width: 20%;
        text-align: center;
        border: 1px solid #10486B;
        color: #10486B;
        font-weight: 500;
      }
    }

    .other-index {
      margin: 30px 10px 10px;

      ul {
        display: flex;
        padding: 0;
        background-color: #10486B;
        justify-content: space-evenly;
        margin-bottom: 0;

        li {
          list-style: none;
          margin: 0;
          padding: 10px;

          &.active {
            background-color: #EE7939;
          }

          a {
            color: #FFFFFF;
          }
        }

        li:hover {
          background-color: #EE7939;
        }
      }

      .table {
        thead {
          tr {
            th {
              padding: 5px;
              border-bottom: 1px solid #10486B;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 5px;
              background-color: #FFFFFF;
              border-top: 0;
              border-bottom: 1px solid #10486B;
            }
          }
        }
      }
    }
  }

  .banner {
    img {
      width: 100%;
    }
  }

  .in-final-footer {
    margin: 5px 15px;
    height: 8px;
    background-color: #068000;
  }
}
</style>
