<template>
  <div id="jpindex">
    <div class="header">
      <img src="@/assets/jp-header.png">
    </div>
    <div class="header-after"></div>
    <div class="nav d-flex py-0">
      <div class="navbar-brand d-flex">
        <div class="navbar-brand-top">Nikkei Indexes</div>
        <div class="navbar-brand-subtop">-Nikkei 225 turns 70 years old-</div>
      </div>
      <div class="navbar-right">
        <ul class="mb-0 pl-0">
          <li class="language-link active"><a href="#">English</a></li>
          <li class="language-link"><a href="#">簡体中文</a></li>
          <li class="language-link"><a href="#">日本語</a></li>
          <li class="members-link"><a href="#">For Subscribers</a></li>
        </ul>
      </div>
    </div>
    <div class="jp-content">
      <div class="row">
        <div class="col-xs-12 col-sm-3 d-flex" style="align-items: center;">
          <div>
            <div class="l-panel">
              <div class="top-nk225-text-nk">
                Nikkei 225
              </div>
              <div class="top-nk225-text">
                <span><div id="datedtime_nkave">{{ currentDate }}{{ currentTime }}</div></span>
              </div>
              <div class="top-nk225-text-link">
                <i class="fas fa-chart-line"></i>&nbsp;<a class="right" href="#">Intraday Chart</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-7">
          <div class="c-panel">
            <div class="align-baseline d-flex p-lg-2 col-12">
              <span style="font-size: 18px;">{{price}}</span>
              <span class="pl-2 pr-2">{{diff}} ({{diffPercent}})</span>
              <i class="fas fa-arrow-circle-right" :class="[isPriceUp?'icon-right-up':'icon-right-down']" style="font-size: 18px;"></i>
            </div>
            <apexchart width="100%" height="200" ref="jpChart" :options="options" :series="series"></apexchart>
          </div>

          <div class="today-result">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>morning</td>
                  <td>top</td>
                  <td>{{ r1ResultThree }}</td>
                  <td>bottom</td>
                  <td>{{ r1ResultTwo }}</td>
                </tr>
                <tr>
                  <td>afternoon</td>
                  <td>top</td>
                  <td>{{ r2ResultThree }}</td>
                  <td>bottom</td>
                  <td>{{ r2ResultTwo }}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="col-xs-12 col-sm-2 d-flex">
          <div class="r-panel d-flex">
            <div class="top-nk225-menu">
              <a href="#"><i class="far fa-arrow-alt-circle-right"></i>&nbsp;More Details</a>
            </div>
            <div class="top-nk225-menu pt-1">
              <a href="#"><i class="far fa-arrow-alt-circle-right"></i>&nbsp;Daily Summary</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jp-footer">
      <div><img src="../../assets/logo_nikkei_footer.svg" title="NIKKEI" alt="NIKKEI"> Nikkei Inc. No reproduction without permission.</div>
      <a href="https://nikkeivipstock.com/" target="_blank" style="color: #FFFFFF; font-size: 110%">www.nikkeivipstock.com</a>
    </div>
    <div class="jp-final-footer"></div>
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'
import moment from '@/moment.js'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'JPIndex',
  components: {
    'apexchart': VueApexCharts
  },
  computed: {
    lastPrice() {
      return this.Prices[this.Prices.length - 1]
    },
    price() {
      if(this.lastPrice) {
        return this.lastPrice.price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    diff() {
      if(this.lastPrice) {
        let diff = parseFloat(this.lastPrice.diff)
        if(diff > 0) {
          return '+'+this.lastPrice.diff
        }else{
          return this.lastPrice.diff
        }
      }else{
        return ''
      }
    },
    diffPercent() {
      if(this.lastPrice) {
        let per = parseFloat(this.lastPrice.per)
        if(per > 0) {
          return '+'+this.lastPrice.per+'%'
        }else{
          return this.lastPrice.per+'%'
        }
      }else{
        return ''
      }
    },
    isPriceUp() {
      if(this.lastPrice) {
        let diff = parseFloat(this.lastPrice.diff)
        if(diff > 0) {
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    },
    currentDate() {
      if(this.lastPrice) {
        return moment(this.Date).format("MMM/DD/YYYY")
      }else{
        return moment().format("MMM/DD/YYYY")
      }
    },
    currentTime() {
      if(this.lastPrice) {
        if(this.lastPrice.note === null){
          let time = moment(`${this.Date} ${this.lastPrice.time}`).format("HH:mm")
          return `(${time})`
        }else{
          return ` *${this.lastPrice.note}`
        }
      }else{
        return ''
      }
    },
    r1ResultThree() {
      if(this.todayResult.r1) {
        let split = parseFloat(this.todayResult.r1.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r1ResultTwo() {
      if(this.todayResult.r1) {
        let split = parseFloat(this.todayResult.r1.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultThree() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultTwo() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    }
  },
  data() {
    return {
      options: {
        chart: {
          id: 'jp-index-chart',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          floating: true
        },
        subtitle: {
          text: '',
          floating: true
        },
        stroke: {
          curve: 'straight', // smooth | straight
          width: 2
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            rotate: 0,
            offsetX: 0,
            offsetY: 0,
            style: {
              colors: '#FFFFFF'
            },
            format: 'HH:mm',
            datetimeUTC: false
          },
          //min: new Date(new Date().setHours(9, 0, 0, 0)).getTime(),
          //max: new Date(new Date().setHours(15, 0, 0, 0)).getTime(),
          tickAmount: 1,
          axisTicks: {
            show: true,
            color: '#FFFFFF'
          }
        },
        yaxis: {
          opposite: true,
          labels: {
            show: true,
            style: {
              colors: '#FFFFFF'
            },
            formatter: function(val) {
              return val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          },
          axisTicks: {
            show: true,
            color: '#FFFFFF',
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 5,
        },
        colors: ['#FFFFFF'],
        grid: {
          show: true,
          borderColor: '#FFFFFF',
          position: 'front',
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        }
      },
      series: [
        {
          name: 'Price',
          type: 'area',
          data: []
        }
      ],
      Prices: [],
      Date: null,
      StartTime: null,
      EndTime: null,
      todayResult: {
        r1: null,
        r2: null
      }
    }
  },
  methods: {
    updateChart() {
      LottoService
        .getJpData()
        .then((response)=>{
          if(response.status === 'success') {

            this.Date = response.data.date
            this.Prices = response.data.prices

            this.StartTime = response.data.tLabels[0]
            this.EndTime = response.data.tLabels[response.data.tLabels.length - 1]
            let splitStart = this.StartTime.split(':')
            let splitEnd = this.EndTime.split(':')

            this.$refs.jpChart.updateOptions({
              ...this.options,
              xaxis: {
                ...this.options.xaxis,
                min: new Date(new Date(response.data.date).setHours(parseInt(splitStart[0]), parseInt(splitStart[1]), 0, 0)).getTime(),
                max: new Date(new Date(response.data.date).setHours(parseInt(splitEnd[0]), parseInt(splitEnd[1]), 0, 0)).getTime()
              },
              /*yaxis: {
                ...this.options.yaxis,
                min: response.data.label[0],
                max: response.data.label[response.data.label.length - 1]
              }*/
            })

            this.$refs.jpChart.updateSeries([{
              name: 'Price',
              data: response.data.prices.map((price)=>{
                return {
                  x: new Date(`${response.data.date} ${price.time}`).getTime(),
                  y: price.price
                }
              })
            }])

            // หาผลของวันนี้
            let r1 = this.Prices.find((p)=>{
              return p.time === '11:05' && p.available === true
            })
            if(r1 !== undefined) {
              this.todayResult.r1 = r1
            }

            let r2 = this.Prices.find((p)=>{
              return p.time === '15:25' && p.available === true
            })
            if(r2 !== undefined) {
              this.todayResult.r2 = r2
            }

          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {

      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().add(120, 'm').isBetween(moment(`${this.Date} ${this.StartTime}`), moment(`${this.Date} ${this.EndTime}`).add(15, 'm')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log(this.StartTime, this.EndTime, 'reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }

    }
  },
  mounted() {
    this.updateChart()
  }
}
</script>
<style lang="scss" scoped>
#jpindex {
  margin-bottom: 30px;
  .icon-right-down {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .icon-right-up {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }
  .header-after {
    margin-top: -4px;
    border-top: 4px solid #005790;
  }
  .nav {
    align-items: center;
    .navbar-brand {
      align-items: center;
      .navbar-brand-top {
        font-size: 16px;
        margin-right: 15px;
        min-width: 120px;
        padding-left: 10px;
      }
      .navbar-brand-subtop {
        font-size: 12px;
        color: #be0000;
      }
    }

    .navbar-right {
      flex: 1;
      text-align: right;
      ul {
        display: flex;
        justify-content: flex-end;
        li.language-link {
          list-style: none;
          a {
            color: #337ab7;
            padding: 5px 10px;
            font-size: 12px;
          }
          a:hover {
            color: #fff;
            background-color: #005790;
            text-decoration: none;
          }
        }
        li.active {
          a {
            color: #fff;
            background-color: #005790;
          }
        }
        li.members-link {
          list-style: none;
          margin-left: 40px;
          min-width: 140px;
          text-align: center;
          background-color: #f0f0f0;
          margin-right: 5px;
          a {
            font-size: 12px;
            color: #337ab7;
            text-decoration: none;
          }
        }
      }
    }
  }
  .jp-content {
    color: #fff;
    background-color: #005790;
    padding: 30px 10px;

    .l-panel {
      border-left: 2px solid #FFFFFF;
      padding-left: 10px;
      .top-nk225-text-nk {
        font-size: 22px;
      }
      .top-nk225-text {
        font-size: 22px;
      }
      .top-nk225-text-link {
        a {
          color: #FFFFFF;
        }
      }
    }
    .r-panel {
      text-align: right;
      flex-direction: column;
      flex: 1;
      justify-content: center;

      .top-nk225-menu {
        text-align: right;
        font-size: 16px;
        //padding-top: 10px;
        a {
          color: #FFFFFF;
        }
      }
    }
    .c-panel {
      padding: 10px 0;
    }
    .today-result {
      margin: auto;
      max-width: 500px;

      .table>tbody>tr>td {
        padding: 5px;
        text-align: center;
        color: #FFF;
      }
    }
  }
  .jp-footer {
    margin-top: 10px;
    padding: 10px 0;
    color: #fff;
    background-color: #005790;
    text-align: center;
  }
  .jp-final-footer {
    margin: 5px 15px;
    height: 8px;
    background-color: #005790;
  }
}

@media (max-width: 991px) {
  #jpindex {
    .jp-content {
      .row {
        flex-direction: column;
        margin-right: 0;
        margin-left: 0;

        .col-xs-12 {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#apexchartsjpxindexxchart {
  margin: 0 auto;
}
</style>
