<template>
  <div class="lsx-chart">
    <apexchart width="100%" height="250" ref="lsxChart" type="candlestick" :options="options" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'LSXChart',
  props: ['data'],
  components: {
    'apexchart': VueApexCharts
  },
  data() {
    return {
      options: {
        chart: {
          id: 'lsxChart',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: true
          }
        }
      },
      series: [{
        data: []
      }]
    }
  },
  computed: {
    items() {
      return (this.data || []).reverse()
    }
  },
  watch: {
    items() {
      this.$refs.lsxChart.updateSeries([{
        data: this.items
      }])
    }
  }
}
</script>
<style lang="scss">
.lsx-chart {
  border: 1px solid #dee2e6;
  background-color: #FFFFFF;
}
</style>
