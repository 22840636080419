<template>
  <div id="cnindex">
    <div class="header">
      <img src="@/assets/cn-header.png">
    </div>
    <div class="header-after"></div>
    <div class="cn-content">
      <div class="text-center" style="padding: 50px 0 40px 0;">
        <span style="font-size: 30px; color: #FFFFFF">SZSE COMPONENT INDEX</span>
      </div>
      <div class="c-selectex-container">
        <select class="form-control">
          <option>SZSE Component Index</option>
          <option>SZSE Composite Index</option>
          <option>SZSE SME Price Index</option>
          <option>ChiNext Index</option>
        </select>
      </div>
      <div class="content-detail">
        <div class="row m-0">
          <div class="col-xs-12 col-sm-4 col-left">
            <div class="p-2">
              <div class="index-detail">
                <div class="title" :class="[isPriceUp?'up':'down']">{{ price }}</div>
                <ul>
                  <li>
                    <span class="name">Date</span>
                    <span class="value">{{ dispDate }}</span>
                  </li>
                  <li>
                    <span class="name">Prev.Close</span>
                    <span class="value">{{ prevClose }}</span>
                  </li>
                  <li class="open-up">
                    <span class="name">Open</span>
                    <span class="value change">
                      <span class="value-cont" :class="[isPriceUp?'up':'down']">
                        <i class="icon"></i>
                        {{ open }}
                      </span>
                    </span>
                  </li>
                  <li class="up">
                    <span class="name">Last</span>
                    <span class="value change">
                      <span class="value-cont" :class="[isPriceUp?'up':'down']">
                        <i class="icon"></i>
                        {{ price }}
                      </span>
                    </span>
                  </li>
                  <li class="up">
                    <span class="name">Change</span>
                    <span class="value change">
                      <span class="value-cont" :class="[isPriceUp?'up':'down']">
                        <i class="icon"></i>
                        {{ change }}
                      </span>
                    </span>
                  </li>
                  <li class="up">
                    <span class="name">Change（%）</span>
                    <span class="value change">
                      <span class="value-cont" :class="[isPriceUp?'up':'down']">
                        <i class="icon"></i>
                        {{ percent }}
                      </span>
                    </span>
                  </li>
                  <li>
                    <span class="name">High</span>
                    <span class="value blue">
                      {{ high }}
                    </span>
                  </li>
                  <li>
                    <span class="name">Low</span>
                    <span class="value blue">
                      {{ low }}
                    </span>
                  </li>
                  <li>
                    <span class="name">Volume（10Bil.）</span>
                    <span class="value">{{ VolumeB }}</span>
                  </li>
                  <li>
                    <span class="name">Amount（100Mil.）</span>
                    <span class="value">{{ AmountM }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-8">
            <div class="p-2">
              <apexchart width="100%" height="280" ref="cnChart" :options="options" :series="series"></apexchart>
            </div>

            <div class="today-result">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>morning</td>
                    <td>top</td>
                    <td>{{ r1ResultThree }}</td>
                    <td>bottom</td>
                    <td>{{ r1ResultTwo }}</td>
                  </tr>
                  <tr>
                    <td>evening</td>
                    <td>top</td>
                    <td>{{ r2ResultThree }}</td>
                    <td>bottom</td>
                    <td>{{ r2ResultTwo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-2">
        <a href="https://shenzhenindex.com/" target="_blank" style="color: #FFFFFF; font-size: 110%">www.shenzhenindex.com</a>
      </div>
    </div>
    <div class="cn-final-footer"></div>
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'
import moment from '@/moment.js'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'CNIndex',
  components: {
    'apexchart': VueApexCharts
  },
  data() {
    return {
      labelTimes: [],
      options: {
        chart: {
          id: 'cn-index-chart',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          floating: true
        },
        subtitle: {
          text: '',
          floating: true
        },
        stroke: {
          curve: 'straight', // smooth | straight
          width: 1
        },
        xaxis: {
          //type: 'datetime',
          type: 'category',
          labels: {
            show: true,
            rotate: 0,
            offsetX: 0,
            offsetY: 0,
            style: {
              colors: '#999'
            },
            //format: 'HH:mm',
            //datetimeUTC: false,
            formatter: (value/*, timestamp, opts*/)=>{
              switch(value) {
                case '09:30':
                  return '09:30'
                case '10:30':
                  return '10:30'
                case '11:30':
                  return '11:30/13:00'
                case '14:00':
                  return '14:00'
                case '15:00':
                  return '15:00'
                default:
                  return ''
              }
            }
          },
          axisTicks: {
            show: false,
            color: '#999'
          }
        },
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            style: {
              colors: '#999'
            },
            formatter: function(val) {
              return val.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          },
          axisTicks: {
            show: false,
            color: '#999',
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 4,
        },
        colors: ['#569afe'],
        grid: {
          show: true,
          borderColor: '#CCCCCC',
          position: 'front',
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        }
      },
      series: [
        {
          name: 'Price',
          type: 'area',
          data: []
        }
      ],
      Prices: [],
      StartTime: null,
      EndTime: null,
      cLabels: null,
      lottoDate: null,
      todayResult: {
        r1: null,
        r2: null
      }
    }
  },
  computed: {
    lastPrice() {
      return this.Prices[this.Prices.length - 1]
    },
    price() {
      if(this.lastPrice) {
        return this.lastPrice.price.toFixed(2);
      }else{
        return ''
      }
    },
    prevClose() {
      if(this.lastPrice) {
        return this.lastPrice.prevClose;
      }else{
        return ''
      }
    },
    open() {
      if(this.lastPrice) {
        return this.lastPrice.open;
      }else{
        return ''
      }
    },
    high() {
      if(this.lastPrice) {
        return this.lastPrice.high;
      }else{
        return ''
      }
    },
    low() {
      if(this.lastPrice) {
        return this.lastPrice.low;
      }else{
        return ''
      }
    },
    percent() {
      if(this.lastPrice) {
        return this.lastPrice.per;
      }else{
        return ''
      }
    },
    change() {
      if(this.lastPrice) {
        return this.lastPrice.diff;
      }else{
        return ''
      }
    },
    VolumeB() {
      if(this.lastPrice) {
        return this.lastPrice.volume
      }else{
        return '-'
      }
    },
    AmountM() {
      if(this.lastPrice) {
        return this.lastPrice.amount
      }else{
        return '-'
      }
    },
    isPriceUp() {
      if(this.lastPrice) {
        let diff = parseFloat(this.lastPrice.diff)
        if(diff > 0) {
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    },
    r1ResultThree() {
      if(this.todayResult.r1) {
        let split = parseFloat(this.todayResult.r1.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r1ResultTwo() {
      if(this.todayResult.r1) {
        let split = parseFloat(this.todayResult.r1.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultThree() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultTwo() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    dispDate() {
      if(this.lottoDate) {
        return moment(this.lottoDate).format('MMM DD, YYYY')
      }else{
        return '-'
      }
    }
  },
  methods: {
    updateChart() {
      LottoService
        .getCnData()
        .then((response)=>{
          if(response.status === 'success') {

            this.lottoDate = response.data.date
            this.Prices = response.data.prices
            this.labelTimes = response.data.tLabels

            this.StartTime = response.data.tLabels[0]
            this.EndTime = response.data.tLabels[response.data.tLabels.length - 1]
            this.cLabels = response.data.labels

            const self = this
            this.$refs.cnChart.updateOptions({
              ...this.options,
              xaxis: {
                ...this.options.xaxis,
                labels: {
                  ...this.options.xaxis.labels,
                  formatter: (value)=>{
                    if(self.cLabels[value])
                      return self.cLabels[value]
                    else
                      return ''
                  }
                }
              }
            })

            let prevPrice = null
            this.$refs.cnChart.updateSeries([{
              name: 'Price',
              data: this.labelTimes.map((label)=>{

                let price = response.data.prices.find((p)=>{
                  return p.time === label
                })

                if(price) {
                  prevPrice = price.price
                  return {
                    x: label,
                    y: price.price
                  }
                }else
                if(moment().isAfter(moment(`${response.data.date} ${label}`))){
                  return {
                    x: label,
                    y: prevPrice
                  }
                }else{
                  return {
                    x: label,
                    y: null
                  }
                }

              })
            }])

            // หาผลของวันนี้
            let r1 = this.Prices.find((p)=>{
              return p.time === '11:05' && p.available === true
            })
            if(r1 !== undefined) {
              this.todayResult.r1 = r1
            }

            let r2 = this.Prices.find((p)=>{
              return p.time === '15:25' && p.available === true
            })
            if(r2 !== undefined) {
              this.todayResult.r2 = r2
            }

          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {
      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().add(60, 'm').isBetween(moment(`${this.lottoDate} ${this.StartTime}`), moment(`${this.lottoDate} ${this.EndTime}`).add(15, 'm')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log('reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }
    }
  },
  mounted() {
    this.updateChart()
  }
}

</script>
<style lang="scss" scoped>
#cnindex {
  margin-bottom: 30px;

  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }

  .header-after {
    margin-top: -5px;
    border-top: 4px solid #162E64;
  }

  .cn-content {
    margin-top: 5px;
    background-image: url('../../assets/cn-bg.jpg');
    background-size: 100% 100%;
    min-height: 300px;
    padding: 0 10px 10px 10px;

    .c-selectex-container {
      select {
        width: 300px;
        background-color: #53607b;
        color: #FFFFFF;
        border-radius: 0;
        font-weight: bold;
        font-size: 16px;
      }

      select:after {
        background-color: #db3338;
      }
    }

    .content-detail {
      margin-top: 15px;
      background-color: #FFFFFF;

      .col-left {
        border-right: 2px solid #2A3A5C;

        .index-detail {
          .title {
            font-size: 36px;
            text-align: center;

            &.up {
              color: #db3338;
            }
            &.down {
              color: #22ac38;
            }
          }
          ul {
            padding-left: 0;
            li {
              list-style: none;
              border-top: 1px solid #eee;
              height: 30px;
              line-height: 29px;

              .name {
                display: inline-block;
                width: 60%;
                padding-left: 6%;
                border-right: 1px solid #eee;
                background-color: #f6f6f6;
                color: #7f7f7f;
              }
              .value {
                display: inline-block;
                width: 40%;
                padding-right: 6%;
                vertical-align: middle;
                text-align: right;

                .value-cont {
                  display: inline-block;
                  width: 70px;
                  .icon {
                    float: left;
                    width: 10px;
                    height: 6px;
                    -webkit-transform: translate(0,10px);
                    -moz-transform: translate(0,10px);
                    -ms-transform: translate(0,10px);
                    -o-transform: translate(0,10px);
                    transform: translate(0,10px);
                    background: url(../../assets/icon-updown-arrow.png) 0 -6px no-repeat;
                    //background-position: 0 0;
                  }

                  &.up {
                    color: #db3338;
                  }
                  &.down {
                    color: #22ac38;
                    .icon {
                      background-position: 0 0;
                    }
                  }

                }
              }
              .change {
                color: #db3338;
              }
            }
          }
        }
      }

      .today-result {
        .table>tbody>tr>td {
          padding: 5px;
          text-align: center;
          color: #7f7f7f;
        }
      }
    }
  }

  .cn-final-footer {
    margin: 5px 15px;
    height: 8px;
    background-color: #29375C;
  }
}

@media (max-width: 991px) {
  #cnindex {
    .cn-content {
      .c-selectex-container {
        select {
          width: 100%;
        }
      }

      .content-detail {
        .row {
          flex-direction: column;

          .col-left {
            border-right: 0;
            flex: none;
            max-width: 100%;
          }

          .col-sm-8 {
            flex: none;
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
