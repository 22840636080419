<template>
  <div class="ru-index">
    <div class="header">
      <img src="@/assets/ru-header.png">
    </div>
    <div class="ru-content">
      <div class="top">
        <div class="left">
          <div class="logo">
            <img src="@/assets/ru-logo.png">
          </div>
        </div>
        <div class="nav-menu">
          <ul>
            <li><a>Indices</a></li>
            <li><a>Major Indices</a></li>
            <li><a class="font-weight-bold">World Indices</a></li>
            <li><a>Indices Futures</a></li>
            <li class="m1"><a>Indices CFDs</a></li>
            <li class="m1"><a>Global Indices</a></li>
            <li class="m1"><a>Futures Spec.</a></li>
          </ul>
        </div>
        <div class="btn-menu">
          <i class="fas fa-bars"></i>
        </div>
      </div>
      <div class="middle">
        <div class="chart">
          <div class="chart-head">
            <div class="title">
              <h1>MOEX Blue Chip (MOEXBC)</h1>
              <div class="flags">
                <i></i>
                <span>Moscow</span>
              </div>
            </div>
            <div class="chart-head-btn">
              <button class="btn btn-success btn-sm">Start Trading</button>
              <div class="risk_warning">| AD | 71.2% of retail investor accounts lose money when trading CFDs with this provider.</div>
            </div>
          </div>
          <div class="chart-content">
            <div class="chart-overview">
              <div class="chart-current-data">
                <div class="chart-data-price" :class="{'down':marketStatus==='down', 'up':marketStatus==='up'}">
                  <div class="data-arrow"></div>
                  <div class="data-prices">
                    <div class="last-prices">
                      <span class="price">{{ price }}</span>
                      <span class="diff status">{{ change }}</span>
                      <span class="percent status">({{ percent }}%)</span>
                    </div>
                    <div class="market-status" :class="{'is-close':marketActive==='close', 'is-open':marketActive==='open'}">
                      <span class="clock"></span>
                      <span class="time">{{ currentTime }}</span>
                      <span class="time-status">- {{marketActive==='close' ? 'Closed.' : 'Real-time Data.'}}</span>
                      <span class="currency"> Currency in RUB ( <a>Disclaimer</a> )</span>
                    </div>
                  </div>
                </div>
                <div class="chart-data-type">
                  <div class="general-info">
                    <div>
                      <span>Type:</span>
                      <span class="elp">Index</span>
                    </div>
                    <div>
                      <span>Market:</span>
                      <span class="elp"><a>Russia</a></span>
                    </div>
                    <div>
                      <span># Components:</span>
                      <span class="elp"><a>15</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chart-summary">
                <ul>
                  <li><strong>Volume:</strong> <span>-</span></li>
                  <li><strong>Open:</strong> <span>{{ open }}</span></li>
                  <li><strong>Day's Range:</strong> <span>{{ dayLow }} - {{ dayHigh }}</span></li>
                </ul>
              </div>
            </div>
            <div class="chart-box">
              <div class="chart-top">
                <ul>
                  <li><a class="candle-icon"></a></li>
                  <li><a class="chart-icon active"></a></li>
                </ul>
                <ul>
                  <li><a class="active">1</a></li>
                  <li><a>5</a></li>
                  <li><a>15</a></li>
                  <li><a>30</a></li>
                  <li><a>1H</a></li>
                  <li><a>5H</a></li>
                  <li><a>1D</a></li>
                  <li><a>1W</a></li>
                  <li><a>1M</a></li>
                </ul>
                <ul>
                  <li><a class="news-icon active"></a></li>
                </ul>
              </div>
              <div class="chart-plot">
                <apexchart width="100%" height="300" ref="ruChart" :options="options" :series="series"></apexchart>
              </div>
              <div class="chart-bottom">
                <ul>
                  <li>1 Day</li>
                  <li>1 Week</li>
                  <li>1 Month</li>
                  <li>3 Months</li>
                  <li>6 Months</li>
                  <li>1 Year</li>
                  <li>5 Years</li>
                  <li>Max</li>
                </ul>
              </div>
            </div>
            <div class="chart-footer">
              <ul>
                <li class="first-child">
                  <div>
                    <span>Prev. Close</span>
                    <span>{{ prevClose }}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <span>Volume</span>
                    <span>N/A</span>
                  </div>
                </li>
                <li class="last-child">
                  <div>
                    <span>Day's Range</span>
                    <span>{{ dayLow }} - {{ dayHigh }}</span>
                  </div>
                </li>
                <li class="first-child">
                  <div>
                    <span>Open</span>
                    <span>{{ open }}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <span>Average Vol. (3m)</span>
                    <span>N/A</span>
                  </div>
                </li>
                <li class="last-child">
                  <div>
                    <span>52 wk Range</span>
                    <span>{{ yearLowest }} - {{ yearHighest }}</span>
                  </div>
                </li>
                <li class="first-child">
                  <div>
                    <span>1-Year Change</span>
                    <span>39.18%</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="today-result">
      <table class="table mb-0">
        <tbody>
          <tr>
            <td>closed</td>
            <td>top</td>
            <td>{{ ResultThree }}</td>
            <td>bottom</td>
            <td>{{ ResultTwo }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="ru-footer">
      <div class="footer">
      </div>
    </div>
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'
import VueApexCharts from 'vue-apexcharts'
import moment from '@/moment'

export default {
  name: 'RUIndex',
  components: {
    'apexchart': VueApexCharts
  },
  data() {
    return {
      lottoDate: null,
      Prices: [],
      diffMins: 0,
      StartTime: null,
      EndTime: null,
      yearHighest: null,
      yearLowest: null,
      todayResult: null,
      options: {
        chart: {
          id: 'ru-index-chart',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          floating: false
        },
        subtitle: {
          text: '',
          floating: false
        },
        stroke: {
          curve: 'straight', // smooth | straight
          width: 2
        },
        xaxis: {
          //type: 'datetime',
          type: 'category',
          labels: {
            show: true,
            rotate: 0,
            offsetX: 0,
            offsetY: 0,
            style: {
              colors: '#000'
            },
            formatter: (value)=>{
              switch(value) {
                case '15:00':
                  return '15:00'
                case '17:00':
                  return '17:00'
                case '19:00':
                  return '19:00'
                case '21:00':
                  return '21:00'
                case '23:00':
                  return '23:00'
                default:
                  return ''
              }
            }
          },
          axisTicks: {
            show: false,
            color: '#999'
          }
        },
        yaxis: {
          opposite: true,
          labels: {
            show: true,
            style: {
              colors: '#000'
            },
            formatter: function(val) {
              return val.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          },
          axisTicks: {
            show: false,
            color: '#999',
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 4,
        },
        colors: ['#2196f3'],
        grid: {
          show: true,
          borderColor: '#eee',
          position: 'back',
          yaxis: {
            lines: {
              show: true
            }
          },
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        }
      },
      series: [
        {
          name: 'Price',
          type: 'area',
          data: []
        }
      ],
    }
  },
  computed: {
    lastPrice() {
      return this.Prices[this.Prices.length - 1]
    },
    price() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.price)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    percent() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? '+'+this.lastPrice.per : this.lastPrice.per;
      }else{
        return ''
      }
    },
    change() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? '+'+this.lastPrice.diff : this.lastPrice.diff;
      }else{
        return ''
      }
    },
    currentTime() {
      if(this.lastPrice) {
        return moment(this.lastPrice.datetime).add(-this.diffMins, 'm').format("HH:mm:ss")
      }else{
        return ''
      }
    },
    marketActive() {
      if(this.lastPrice) {
        if( moment().add(this.diffMins, 'm').isBetween(moment(`${this.lottoDate} ${this.StartTime}`), moment(`${this.lottoDate} ${this.EndTime}`).add(10, 'm'))) {
          return 'open'
        }else{
          return 'close'
        }
      }
      return 'close'
    },
    marketStatus() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? 'up' : 'down';
      }else{
        return ''
      }
    },
    prevClose() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.prevClose)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    open() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.open)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    dayHigh() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.high)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    dayLow() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.low)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    ResultThree() {
      if(this.todayResult) {
        let split = parseFloat(this.todayResult.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    ResultTwo() {
      if(this.todayResult) {
        let split = parseFloat(this.todayResult.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    }
  },
  methods: {
    updateChart() {
      //this.$axios.$get('chart/ru?t='+new Date().getTime())
      LottoService
        .getRuData()
        .then((response)=>{
          console.log(response)
          if(response.status === 'success') {
            const data = response.data

            this.diffMins = data.diffMins
            this.lottoDate = data.date
            this.Prices = data.prices
            this.StartTime = data.times[0]
            this.EndTime = data.times[data.times.length - 1]
            this.yearHighest = parseFloat(data.yearHighest).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.yearLowest = parseFloat(data.yearLowest).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            let prevPriceOpt = null
            this.$refs.ruChart.updateSeries([{
              name: 'Price',
              data: data.times.map((label)=>{

                let priceOpt = data.prices.find((p)=>{
                  return p.time === label
                })

                let thTimeLabel = moment(`${data.date} ${label}`).add(-this.diffMins, 'm').format("HH:mm")
                if(priceOpt) {
                  prevPriceOpt = priceOpt
                  return {
                    x: thTimeLabel,
                    y: parseFloat(priceOpt.price)
                  }
                }else
                if(moment().add(this.diffMins, 'm').isAfter(moment(`${data.date} ${label}`))){
                  return {
                    x: thTimeLabel,
                    y: parseFloat(prevPriceOpt.price)
                  }
                }else{
                  return {
                    x: thTimeLabel,
                    y: null
                  }
                }

              })
            }])

            // หาผลของวันนี้
            let r2 = this.Prices.find((p)=>{
              return p.time === '19:50' && p.available === true
            })
            if(r2 !== undefined) {
              this.todayResult = r2
            }
          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {
      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().add(this.diffMins, 'm').isBetween(moment(`${this.lottoDate} ${this.StartTime}`), moment(`${this.lottoDate} ${this.EndTime}`).add(10, 'm')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log('reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }
    }
  },
  mounted() {
    this.updateChart()
  }
}
</script>
<style lang="scss" scoped>
.ru-index {
  margin-bottom: 20px;
  background-color: #fff;

  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }

  .ru-content {
    margin-top: -4px;
    .top {
      background-color: #1A2B1F;
      display: flex;
      align-items: center;

      .left {
        display: flex;

        .logo {
          padding: 0 15px;
          cursor: pointer;
        }
      }

      .nav-menu {
        font-size: 15px;

        ul {
          display: flex;
          margin: 0;
          padding: 0;
          color: #fff;

          li {
            list-style: none;
            cursor: pointer;
            font-weight: 500;
            padding: 0 10px;

            a {
              color: #fff;
            }

            a:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .btn-menu {
        display: none;
      }
    }

    .middle {
      padding: 15px;

      .chart {
        .chart-head {
          display: flex;
          padding-bottom: 5px;
          border-bottom: 1px solid #dadada;

          .title {
            width: 100%;

            h1 {
              font-size: 22px;
              font-weight: bold;
              margin-bottom: 0;
            }

            .flags {
              display: flex;
              font-weight: bold;
              color: gray;

              i {
                width: 16px;
                height: 15px;
                background-image: url('../../assets/ce_flags_v25.png');
                background-repeat: no-repeat;
                padding: 0;
                display: inline-block;
                background-position: -17px -1003px;
              }

              span {
                margin-left: 5px;
              }
            }
          }

          .chart-head-btn {
            width: 300px;
            text-align: right;

            button {
              width: 100%;
              font-weight: bold;
              font-size: 12px;
              padding: 2px;
            }

            .risk_warning {
              font-size: 8px;
              text-align: center;
              margin-top: 3px;
              color: #9B9B9B;
              line-height: 9px;
            }
          }
        }

        .chart-content {
          .chart-overview {
            margin-top: 15px;

            .chart-current-data {
              display: flex;

              .chart-data-price {
                width: 100%;
                display: flex;

                .data-arrow {
                  width: 21px;
                  height: 21px;
                  background-image: url('../../assets/newSiteIconsSprite_v42j.png');
                  margin: 2px 5px 0 3px;
                }

                .data-prices {
                  width: 100%;

                  .last-prices {
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 2px;

                    .price {
                      font-size: 26px;
                      line-height: 1.1;
                      font-weight: bold;
                      margin-right: 11px;
                    }

                    .diff {
                      font-size: 20px;
                      line-height: 1.1;
                      font-weight: bold;
                      margin-right: 11px;
                      letter-spacing: 0.5px;
                    }

                    .percent {
                      font-size: 20px;
                      line-height: 1.1;
                      font-weight: bold;
                      letter-spacing: 0.5px;
                    }
                  }

                  .market-status {
                    display: flex;
                    width: 100%;
                    margin-top: 5px;
                    align-items: center;
                    font-size: 11px;
                    color: #9b9b9b;

                    .clock {
                      background-image: URL('../../assets/newSiteIconsSprite_v42j.png');
                      width: 14px;
                      height: 14px;
                    }

                    .time {
                      margin-left: 5px;
                      font-weight: bold;
                    }

                    .time-status {
                      margin-left: 5px;
                      font-weight: bold;
                    }

                    .currency {
                      margin-left: 5px;
                      font-weight: bold;

                      a {
                        color: gray;
                        text-decoration: underline;
                        cursor: pointer;
                      }
                    }

                    &.is-close {
                      font-size: 11px;
                      font-weight: normal;

                      .clock {
                        background-position: -121px -9px;
                      }
                    }

                    &.is-open {
                      font-size: 11px;
                      font-weight: normal;

                      .clock {
                        background-position: -121px -27px;
                      }
                    }
                  }
                }

                &.down {
                  .data-arrow {
                    background-position: -96px -478px;
                  }

                  .data-prices {
                    .last-prices {
                      .status {
                        color: red;
                      }
                    }
                  }
                }

                &.up {
                  .data-arrow {
                    background-position: -70px -478px;
                  }

                  .data-prices {
                    .last-prices {
                      .status {
                        color: #0ea600;
                      }
                    }
                  }
                }
              }

              .chart-data-type {
                .general-info {
                  width: 150px;
                  font-size: 11px;
                  color: #9b9b9b;
                  border-left: 1px solid #dadada;
                  padding-left: 15px;

                  div {
                    display: flex;

                    span {
                      display: block;
                      width: 100%;

                      a {
                        color: #1256a0;
                        cursor: pointer;
                      }
                    }

                    span.elp {
                      width: 60px;
                    }
                  }
                }
              }
            }

            .chart-summary {
              margin-top: 15px;
              border-top: 1px solid #dadada;
              padding-top: 6px;

              ul {
                display: flex;
                padding-left: 0;
                line-height: 1.1;

                li {
                  list-style: none;
                  margin-left: 10px;
                  padding-left: 10px;
                  border-left: 1px solid #dadada;

                  strong {
                    color: #9b9b9b;
                    font-weight: 600;
                  }

                  span {
                    font-weight: bold;
                    color: #333;
                  }
                }

                li:first-child {
                  margin-left: 0;
                  padding-left: 0;
                  border-left: 0;
                }
              }
            }
          }

          .chart-box {
            border: 1px solid #c7c7c7;
            //min-height: 400px;
            margin-bottom: 20px;

            .chart-top {
              border-bottom: 1px solid #c7c7c7;
              padding: 5px;
              display: flex;

              ul {
                margin: 0 10px 0 0;
                padding: 0 10px 0 0;
                display: flex;
                border-right: 1px solid #bfbfbf;

                li {
                  list-style: none;
                  display: flex;

                  a {
                    border-radius: 2px;
                    border: 1px solid #fff;
                    cursor: pointer;
                    display: block;
                    width: 22px;
                    height: 22px;
                    margin: 0 2px;
                    text-align: center;
                    padding-top: 2px;
                    color: #007bff;
                    font-weight: bold;

                    &.active {
                      border: 1px solid #dadada;
                      background-color: #f8f8f8;
                      font-weight: bold;
                    }
                  }

                  a:hover {
                    border: 1px solid #dadada;
                    background-color: #f8f8f8;
                    text-decoration: none;
                  }

                  .candle-icon {
                    width: 21px;
                    height: 21px;
                    background-image: url('../../assets/newSiteIconsSprite_v42j.png');
                    background-position: -4px -1031px;
                  }

                  .chart-icon {
                    width: 21px;
                    height: 21px;
                    background-image: url('../../assets/newSiteIconsSprite_v42j.png');
                    background-position: -40px -1030px;
                  }

                  .news-icon {
                    width: 22px;
                    height: 22px;
                    background-image: url('../../assets/newSiteIconsSprite_v42j.png');
                    background-position: -2px -1256px
                  }
                }
              }

              ul:last-child {
                border-right: 0;
              }
            }

            .chart-plot {
              min-height: 300px;
              padding: 10px;
            }

            .chart-bottom {
              padding: 0 0 20px 0;
              display: flex;
              justify-content: center;

              ul {
                padding: 0;
                margin: 0;
                display: flex;

                li {
                  list-style: none;
                  border: 1px solid #dadada;
                  border-left: 0;
                  padding: 2px;
                  cursor: pointer;
                  width: 75px;
                  text-align: center;
                }

                li:first-child {
                  border-left: 1px solid #dadada;
                }
              }
            }
          }

          .chart-footer {
            margin-bottom: 20px;

            ul {
              display: flex;
              padding: 0;
              margin: 0;
              flex-direction: row;
              flex-wrap: wrap;

              li {
                list-style: none;
                width: 33.33%;
                margin-bottom: 5px;

                div {
                  margin-right: 20px;
                  border-bottom: 1px solid #dadada;

                  span:last-child {
                    display: block;
                    float: right;
                    font-weight: bold;
                  }
                }
              }

              li.last-child {
                div {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .today-result {
    padding: 0 15px;
    .table {
      border-radius: 10px;
      background-color: #1A2B1F;

      tr {
        td {
          border: 1px solid #fff;
          padding: 5px;
          width: 20%;
          text-align: center;
          color: #fff;
        }
      }
    }
  }

  .ru-footer {
    margin-bottom: 20px;
    padding: 0 15px 15px;

    .footer {
      border-bottom: 8px solid #1A2B1F;
      background-color: #fff;
      text-align: center;
      padding: 5px 0;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 991px) {
  .ru-index {
    .ru-content {
      .top {

        .nav-menu {
          ul {
            li {
              font-size: 12px;

              &.m1 {
                display: none;
              }
            }
          }
        }

      }
    }
  }
}

@media screen and (max-width: 767px) {
  .ru-index {
    .header {
      height: auto;
      background-image: none;
      img {
        display: block;
        width: 100%;
      }
    }

    .ru-content {
      .top {
        justify-content: space-between;
        .left {
          .logo {
            img {
              height: 28px;
            }
          }
        }
        .nav-menu {
          display: none;
        }
        .btn-menu {
          display: block;
          color: #eee;
          margin-right: 15px;
          font-size: 22px;
        }
      }
      .middle {
        .chart {
          .chart-content {
            .chart-box {
              .chart-bottom {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .ru-index {
    .ru-content {
      .middle {
        .chart {
          .chart-head {
            .chart-head-btn {
              display: none;
            }
          }
          .chart-content {

            .chart-overview {
              .chart-current-data {
                .chart-data-type {
                  display: none;
                }
              }
            }

            .chart-box {
              .chart-top {
                overflow: hidden;
              }
              .chart-bottom {
                display: none;
              }
            }

            .chart-footer {
              ul {
                li {
                  width: 100%;
                  div {
                    margin-right: 0;
                    border-bottom: 1px solid #f5f5f5;
                    span {
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .ru-index {
    .ru-content {
      .middle {
        .chart {
          .chart-content {
            .chart-overview {
              margin-bottom: 10px;

              .chart-summary {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
