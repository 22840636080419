<template>
  <div id="vn-index">
    <div class="header">
      <img src="@/assets/vn-header.png">
    </div>
    <div class="header-after"></div>
    <div class="vn-data">
      <h4>Vietnam Ho Chi Minh Stock Index ( {{datetime}} )</h4>
      <div class="row">
        <div class="col-md-4 index_summary">
          <ul>
            <li>
              <span>Vi-VIP</span>
              <strong :class="[{'text-success': isUp}, {'text-danger': isDown}]" class="current">{{ price.close | amountFormat }}</strong>
            </li>
            <li>
              <span>Open</span>
              <span>{{ price.open | amountFormat }}</span>
            </li>
            <li>
              <span>Change</span>
              <span :class="[{'text-success': isUp}, {'text-danger': isDown}]">
                <span v-if="isUp"><img src="@/assets/vn/ico_up.gif"></span>
                <span v-else-if="isDown"><img src="@/assets/vn/ico_down.gif"></span>
                {{ price.change | amountFormat }}
              </span>
            </li>
            <li>
              <span>Change（%)</span>
              <span :class="[{'text-success': isUp}, {'text-danger': isDown}]">
                <span v-if="isUp"><img src="@/assets/vn/ico_up.gif"></span>
                <span v-else-if="isDown"><img src="@/assets/vn/ico_down.gif"></span>
                {{ price.percent | amountFormat }}
              </span>
            </li>
            <li>
              <span>High</span>
              <span>{{ price.high | amountFormat }}</span>
            </li>
            <li>
              <span>Low</span>
              <span>{{ price.low | amountFormat }}</span>
            </li>
            <li>
              <span>Total Value (Mil.)</span>
              <span>{{ price.val | amountFormat(0) }}</span>
            </li>
            <li>
              <span>Total volume (Mil.)</span>
              <span>{{ price.vol | amountFormat(0) }}</span>
            </li>
          </ul>
          <div class="banner my-2">
            <img src="@/assets/vn/mbb1.jpg">
          </div>
        </div>
        <div class="col-md-8">
          <VNXChart :date="date" :times="times" :prices="prices" />
          <VNXResult :data="awards" class="mt-3" />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="row no-gutters">
        <div class="cols col-md-6 l">
          <div class="f-logo">
            <img src="@/assets/vindex.png">
          </div>
        </div>
        <div class="cols col-md-6 r">
          <span class="mr-1">Copyright</span> <a href="https://vnindexvip.com/" target="_blank" style="color: #01579B;"> <strong>www.vnindexvip.com</strong> </a>. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from '@/moment'
import VnService from '@/services/vnService'

import VNXChart from './vn-components/VNXChart'
import VNXResult from './vn-components/VNXResult'

export default {
  name: 'VNIndex',
  components: {
    VNXChart,
    VNXResult
  },
  data() {
    return {
      interval: null,
      count: 0,
      today: moment().format("YYYY-MM-DD"),
      date: moment().format("YYYY-MM-DD"),
      prices: [],
      awards: {
        sec1: {
          three: "",
          two: ""
        },
        sec2: {
          three: "",
          two: ""
        },
        sec3: {
          three: "",
          two: ""
        }
      },
      times: []
    }
  },
  computed: {
    price() {
      if(!this.prices.length) {
        return {
          available: false,
          time: null,
          close: 0,
          change: 0,
          percent: 0,
          high: 0,
          low: 0,
          vol: 0,
          val: 0,
          open: 0
        }
      }else{
        return this.prices[this.prices.length - 1]
      }
    },
    datetime() {
      if(!this.date)
        return ''

      const date = moment(this.date).format('YYYY/MM/DD')
      if(this.price.time === '09:40') {
        return `${date} Break`
      }else
      if(this.price.time === '14:10'){
        return `${date} Break`
      }else
      if(this.price.time === '16:45'){
        return `${date} Close`
      }else{
        return `${date} ${this.price.time}`
      }
    },
    isUp() {
      return this.price.open < this.price.close
    },
    isDown() {
      return this.price.open > this.price.close
    },
    chartData() {
      const histories = this.histories.map((data)=>{
        return {
          x: moment(data.date).format('DD/MM'),
          y: [data.price.open, data.price.high, data.price.low, data.price.close]
        }
      })

      const priceDate = moment(this.price?.time).format('YYYY-MM-DD')
      if(this.histories?.[0]?.date !== priceDate) {
        const todayChart = [{
          x: moment(this.price.time).format('DD/MM'),
          y: [this.price.open, this.price.high, this.price.low, this.price.close]
        }]

        return todayChart.concat(histories)
      }else{
        return histories
      }
    }
  },
  methods: {
    loadData() {
      VnService.getPrices()
      .then((response)=>{
        if(response.status === 'success') {

          this.date = response.data.date
          this.prices = response.data.prices
          this.awards = response.data.awards
          this.times = response.data.times

          // if(this.prices.length) {
          //   this.$store.dispatch('updatePrice', this.prices[this.prices.length - 1])
          // }
        }
      })
    }
  },
  created() {
    this.loadData()

    this.interval = setInterval(()=>{
      this.count+=1
      this.loadData()
    }, 35000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>
<style lang="scss" scoped>
#vn-index {
  margin-bottom: 30px;

  .banner {
    img {
      max-width: 100%;
    }
  }

  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }

  .header-after {
    margin-top: -4px;
    border-top: 4px solid #01579B;
  }

  .vn-data {
    padding: 10px;

    h4 {
      // margin: 0;
      font-weight: bold;
      font-size: 20px;
    }

    .index_summary {

      ul {
        padding: 0;
        li {
          list-style: none;
          padding: 4px 0;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #EEEEEE;

          .current {
            font-size: 110%;
          }
        }
      }

      table.summary {
        thead {
          background-color: #F8F8F8;
          border-top: 2px solid #396DB4;

          tr {
            th {
              font-size: 75%;
              text-align: center;
              padding: 5px 0;
              vertical-align: middle;
              color: #396DB4;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 80%;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .footer {
    margin: 5px 5px 0 5px;
    padding: 5px;
    border: 2px solid #01579B;
    font-size: 85%;
    border-radius: 5px;

    .cols.l {
      display: flex;

      .f-logo {
        margin-right: 10px;

        img {
          height: 35px;
        }
      }

      .f-logo-text {
        line-height: 1.3;
        color: #CC3B44;
        font-weight: bold;

        .la {
          font-size: 110%;
        }

        .en {
          font-size: 80%;
        }
      }
    }

    .cols.r {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 576px) {
  #vn-index {
    .vn-data {
      h4 {
        font-size: 16px;
      }
    }
  }
}
</style>
