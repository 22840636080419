<template>
  <div class="lsx-chart">
    <div class="chart-range">
      <ul>
        <li class="active">1D</li>
        <li>5D</li>
        <li>1M</li>
        <li>3M</li>
        <li>6M</li>
        <li>YTD</li>
        <li>1Y</li>
        <li>ALL</li>
      </ul>
    </div>
    <apexchart width="100%" height="300" ref="vnxChart" :options="options" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'VNXChart',
  components: {
    'apexchart': VueApexCharts
  },
  props: ['date', 'prices', 'times'],
  data() {
    return {
      options: {
        chart: {
          id: 'vnxChart',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          floating: true
        },
        subtitle: {
          text: '',
          floating: true
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            rotate: 0,
            offsetX: 0,
            offsetY: 0,
            style: {
              colors: '#666666'
            },
            format: 'HH:mm',
            datetimeUTC: false
          },
          tickAmount: 1,
          axisTicks: {
            show: true,
            color: '#EEEEEE'
          }
        },
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            style: {
              colors: '#666666'
            },
            formatter: function(val) {
              return val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          },
          axisTicks: {
            show: true,
            color: '#EEEEEE',
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 5,
        },
        colors: ['#1D8E1D'],
        grid: {
          show: false,
          borderColor: '#EEEEEE',
          position: 'front',
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        }
      },
      series: [
        {
          name: 'Price',
          type: 'area',
          data: []
        }
      ]
    }
  },
  watch: {
    times() {
      if(this.times.length) {

        const StartTime = this.times[0]
        const EndTime = this.times[this.times.length - 1]
        let splitStart = StartTime.split(':')
        let splitEnd = EndTime.split(':')

        this.$refs.vnxChart.updateOptions({
          ...this.options,
          xaxis: {
            ...this.options.xaxis,
            min: new Date(new Date(this.date).setHours(parseInt(splitStart[0]), parseInt(splitStart[1]), 0, 0)).getTime(),
            max: new Date(new Date(this.date).setHours(parseInt(splitEnd[0]), parseInt(splitEnd[1]), 0, 0)).getTime()
          }
        })
      }
    },
    prices() {
      if(this.prices.length) {
        this.$refs.vnxChart.updateSeries([{
          name: 'Price',
          data: this.prices.map((price)=>{
            return {
              x: new Date(`${this.date} ${price.time}`).getTime(),
              y: price.close
            }
          })
        }])
      }
    }
  }
}
</script>
<style lang="scss">
.lsx-chart {
  border: 1px solid #dee2e6;

  .chart-range {
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-evenly;
      background-color: #F8F9FA;

      li {
        list-style: none;
        padding: 4px 0;
        width: 100%;
        text-align: center;
        cursor: pointer;
        color: #545B66;
        border-bottom: 1px solid #dee2e6;
      }

      li.active {
        background-color: #E1F5FE;
        border-bottom: 1px dashed #396DB4;
        font-weight: bold;
      }

      li:hover {
        background-color: #EFF2F5;
      }
    }
  }
}
</style>
