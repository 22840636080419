<template>
  <div class="dj-index">
    <div class="header">
      <img src="@/assets/dj-header.png">
    </div>
    <div class="dj-content">
      <div class="chart-section" :class="{'price-down':marketStatus==='down', 'price-up':marketStatus==='up'}">
        <div class="price-groups">
          <div class="price-info">
            <div class="title">
              <h2>Dow Jones Industrial Average</h2>
            </div>
            <div class="price-box">
              <div class="current">
                <h1>{{ price }}</h1>
                <span>USD</span>
              </div>
              <div class="changed">
                <div class="absolute">{{ change }}</div>
                <div class="percent">{{ percent }}% <i class="fas" :class="{'fa-caret-down':marketStatus==='down', 'fa-caret-up':marketStatus==='up'}"></i></div>
              </div>
              <div class="price-time">
                <span>AS OF <span v-if="marketActive==='open'">{{ time }}</span> {{ date }} GMT+7</span>
              </div>
            </div>
            <ul>
              <li>
                <div>
                  <label>OPEN</label>
                  <span>{{ open }}</span>
                </div>
              </li>
              <li>
                <div>
                  <label>PREV CLOSE</label>
                  <span>{{ prevClose }}</span>
                </div>
              </li>
              <li>
                <div>
                  <label>DAY RANGE</label>
                  <span>{{ dayLow }} - {{ dayHigh }}</span>
                </div>
              </li>
              <li>
                <div>
                  <label>52 WEEK RANGE</label>
                  <span>{{ yearLowest }} - {{ yearHighest }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="chart-box">
          <ul>
            <li class="active">1D</li>
            <li>1M</li>
            <li>6M</li>
            <li>YTD</li>
            <li>1Y</li>
            <li>5Y</li>
          </ul>
          <apexchart width="100%" height="300" ref="gbChart" :options="options" :series="series"></apexchart>
          <div class="today-result">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td>closed</td>
                  <td>top</td>
                  <td>{{ ResultThree }}</td>
                  <td>bottom</td>
                  <td>{{ ResultTwo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="ukx-profile">
        <h2>DJIA :: Dow Jones Industrial Average</h2>
        <p>The Dow Jones Industrial Average, Dow Jones, or simply the Dow, is a price-weighted measurement stock market index of 30 prominent companies listed on stock exchanges in the United States.</p>
      </div>
    </div>
    <div class="dj-footer">
      <div class="footer">
        DJIA - Dow Jones Industrial Average<br />
        <a href="https://dowjonespowerball.com/" target="_blank" style="font-weight: normal;">www.dowjonespowerball.com</a>
      </div>
    </div>
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'
import VueApexCharts from 'vue-apexcharts'
import moment from '@/moment'

export default {
  name: 'DJIndex',
  components: {
    'apexchart': VueApexCharts
  },
  data() {
    return {
      lottoDate: null,
      Prices: [],
      diffMins: 0,
      StartTime: null,
      EndTime: null,
      yearHighest: null,
      yearLowest: null,
      todayResult: null,
      options: {
        chart: {
          id: 'dj-index-chart',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          floating: false
        },
        subtitle: {
          text: '',
          floating: false
        },
        stroke: {
          curve: 'straight', // smooth | straight
          width: 1
        },
        xaxis: {
          //type: 'datetime',
          type: 'category',
          labels: {
            show: true,
            rotate: 0,
            offsetX: 0,
            offsetY: 0,
            style: {
              colors: '#666'
            },
            formatter: (value)=>{
              switch(value) {
                case '16:00':
                  return '16:00'
                case '18:00':
                  return '18:00'
                case '20:00':
                  return '20:00'
                case '22:00':
                  return '22:00'
                case '00:00':
                  return '00:00'
                default:
                  return ''
              }
            }
          },
          axisTicks: {
            show: false,
            color: '#999'
          }
        },
        yaxis: {
          opposite: true,
          labels: {
            show: true,
            style: {
              colors: '#666'
            },
            formatter: function(val) {
              // return val.toFixed(2).toString()
              return val.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          },
          axisTicks: {
            show: false,
            color: '#999',
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 3,
        },
        colors: ['#5C0A1A'],
        grid: {
          show: true,
          borderColor: '#eee',
          position: 'back',
          yaxis: {
            lines: {
              show: true
            }
          },
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        }
      },
      series: [
        {
          name: 'Price',
          type: 'area',
          data: []
        }
      ],
    }
  },
  computed: {
    lastPrice() {
      return this.Prices[this.Prices.length - 1]
    },
    date() {
      if(this.lastPrice) {
        return moment(this.lottoDate).format("MM/DD/YYYY")
      }else{
        return ''
      }
    },
    time() {
      if(this.lastPrice) {
        return moment(this.lastPrice.datetime).format("h:mm a")
      }else{
        return ''
      }
    },
    price() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.price)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    percent() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? '+'+this.lastPrice.per : this.lastPrice.per;
      }else{
        return ''
      }
    },
    change() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? '+'+this.lastPrice.diff : this.lastPrice.diff;
      }else{
        return ''
      }
    },
    currentTime() {
      if(this.lastPrice) {
        return moment(this.lastPrice.datetime).format("HH:mm:ss")
      }else{
        return ''
      }
    },
    marketActive() {
      if(this.lastPrice) {
        if( moment().isBetween(moment(`${this.lottoDate} ${this.StartTime}`), moment(`${this.lottoDate} ${this.EndTime}`).add(10, 'm').add(1, 'd') )) {
          return 'open'
        }else{
          return 'close'
        }
      }
      return 'close'
    },
    marketStatus() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? 'up' : 'down';
      }else{
        return ''
      }
    },
    prevClose() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.prevClose)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    open() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.open)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    dayHigh() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.high)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    dayLow() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.low)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    ResultThree() {
      if(this.todayResult) {
        let split = parseFloat(this.todayResult.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    ResultTwo() {
      if(this.todayResult) {
        let split = parseFloat(this.todayResult.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    }
  },
  methods: {
    updateChart() {
      //this.$axios.$get('chart/gb?t='+new Date().getTime())
      LottoService
        .getDjData()
        .then((response)=>{
          //console.log(response)
          if(response.status === 'success') {
            const data = response.data

            // this.diffMins = data.diffMins
            this.lottoDate = data.date
            this.Prices = data.prices
            this.StartTime = data.times[0]
            this.EndTime = data.times[data.times.length - 1]
            this.yearHighest = parseFloat(data.yearHighest).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.yearLowest = parseFloat(data.yearLowest).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            // let prevPriceOpt = null
            this.$refs.gbChart.updateSeries([{
              name: 'Price',
              data: data.times.map((label)=>{

                let priceOpt = data.prices.find((p)=>{
                  return p.time === label
                })

                let thTimeLabel = moment(`${data.date} ${label}`).format("HH:mm")
                if(priceOpt) {
                  // prevPriceOpt = priceOpt
                  return {
                    x: thTimeLabel,
                    y: parseFloat(priceOpt.price)
                  }
                }else
                // if(moment().isAfter(moment(`${data.date} ${label}`))){
                //   return {
                //     x: thTimeLabel,
                //     y: parseFloat(prevPriceOpt.price)
                //   }
                // }else
                {
                  return {
                    x: thTimeLabel,
                    y: null
                  }
                }
              })
            }])

            // หาผลของวันนี้
            let r2 = this.Prices.find((p)=>{
              return p.time === '00:30' && p.available === true
            })
            if(r2 !== undefined) {
              this.todayResult = r2
            }

          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {
      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().isBetween(moment(`${this.lottoDate} ${this.StartTime}`), moment(`${this.lottoDate} ${this.EndTime}`).add(10, 'm').add(1, 'd')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log('reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }
    }
  },
  mounted() {
    this.updateChart()
  }
}
</script>
<style lang="scss" scoped>
.dj-index {
  margin-bottom: 30px;
  background-color: #fff;
  color: #333;

  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }

  .dj-content {
    margin-top: -4px;
    border-top: 4px solid #5C0A1A;

    .chart-section {
      display: flex;
      padding: 15px 15px 15px 10px;

      .price-groups {
        .price-info {
          width: 300px;

          .title {
            h1, h2 {
              line-height: 1;
              padding: 0;
              margin: 0;
              font-size: 22px;
              font-weight: bold;
              display: block;
              width: 100%;
            }
          }

          .price-box {
            margin-top: 25px;

            .current {
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;

              h1 {
                font-weight: bold;
                margin-bottom: 0;
                font-style: 45px;
              }

              span {
                padding-bottom: 4px;
                margin-left: 5px;
              }
            }

            .changed {
              display: flex;
              font-size: 18px;
              justify-content: flex-end;

              .absolute {
                margin-right: 15px;
              }
            }

            .price-time {
              text-align: right;
              span {
                font-size: 12px;
                color: #999;
              }
            }
          }

          ul {
            margin: 20px 0;
            padding: 0;
            font-size: 14px;

            li {
              list-style: none;
              div {
                border-bottom: 1px dashed #999;
                display: flex;
                justify-content: space-between;
                padding-top: 15px;

                label {
                  margin-bottom: 0;
                }
                span {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      &.price-down {
        border-left: 8px solid red;

        .changed {
          .percent {
            .fas {
              color: red;
            }
          }
        }
      }

      &.price-up {
        border-left: 8px solid #1ecd93;

        .changed {
          .percent {
            .fas {
              color: #1ecd93;
            }
          }
        }
      }

      .chart-box {
        width: 100%;
        padding-left: 30px;

        ul {
          padding: 0;
          margin: 0;
          display: flex;
          border-bottom: 1px solid #333;

          li {
            list-style: none;
            margin: 0 10px;
            cursor: pointer;

            &.active {
              border-bottom: 3px solid #000;
              margin-left: 0;
              font-weight: bold;
            }
          }
        }

        .today-result {
          .table {
            tr {
              td {
                border: 1px solid #5C0A1A;
                padding: 5px;
                width: 20%;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .ukx-profile {
      padding: 15px;
      h2 {
        font-size: 24px;
        //margin-bottom: 0;
        font-weight: bold;
      }
    }
  }

  .dj-footer {
    margin-bottom: 20px;
    padding: 0 15px 15px;

    .footer {
      border-bottom: 8px solid #5C0A1A;
      background-color: #fff;
      text-align: center;
      padding: 5px 0;
      font-weight: bold;
    }
  }
}

@media (max-width: 991px) {
  .dj-index {
    .dj-content {
      .chart-section {
        flex-direction: column;
        padding: 15px 15px 15px 0;

        &.price-up, &.price-down {
          border-left: 0;
        }

        &.price-up {
          .price-groups {
            border-left: 8px solid #1ecd93;
          }
        }

        &.price-down {
          .price-groups {
            border-left: 8px solid red;
          }
        }

        .price-groups {
          padding-left: 10px;

          .price-info {
            width: 100%;
          }
        }

        .chart-box {
          padding-left: 15px;
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
