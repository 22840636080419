import api from "@/services/apiService"

class LottoService {

  getJpData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API + 'jp?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getCnData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API + 'cn?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getTwData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API + 'tw?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getHkData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API + 'hk?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getKrData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API + 'kr?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getSgData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API + 'sg?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getGbData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API2 + 'chart/gb?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getDeData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API2 + 'chart/de?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getRuData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API2 + 'chart/ru?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getDjData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API3 + 'chart?t='+new Date().getTime())
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  getInData() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_CLOUD_API + 'in?t='+new Date().getTime())
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

}

export default new LottoService();
