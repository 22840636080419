<template>
  <div class="vnx-result">
    <table class="table table-sm table-bordered">
      <tbody>
        <tr>
          <td width="20%">morning</td>
          <td width="20%">top</td>
          <td width="20%">{{ data.sec1.three }}</td>
          <td width="20%">bottom</td>
          <td width="20%">{{ data.sec1.two }}</td>
        </tr>
        <tr>
          <td>afternoon</td>
          <td>top</td>
          <td>{{ data.sec2.three }}</td>
          <td>bottom</td>
          <td>{{ data.sec2.two }}</td>
        </tr>
        <tr>
          <td>evening</td>
          <td>top</td>
          <td>{{ data.sec3.three }}</td>
          <td>bottom</td>
          <td>{{ data.sec3.two }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'VNXResult',
  props: ['data']
}
</script>
<style lang="scss" scoped>
.vnx-result {
  .table {
    tbody {
      tr {
        td {
          text-align: center;
          border: 1px solid #01579B;
          background-color: #E1F5FE;
          font-size: 90%;
        }
      }
    }
  }
}
</style>
