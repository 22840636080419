<template>
  <div id="la-index">
    <div class="header">
      <img src="@/assets/la-header.png">
    </div>
    <div class="header-after"></div>

    <div class="index_summary">
      <h4>Composite ( {{datetime}} )</h4>
      <table class="summary table table-sm table-bordered">
        <thead>
          <tr>
            <th width="16.66">Opening</th>
            <th width="16.66">Last</th>
            <th width="16.66">Change</th>
            <th width="16.66">High</th>
            <th width="16.66">Low</th>
            <th width="16.66">Trading<br>Value<br>(LAK)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ price.open | amountFormat }}</td>
            <td>{{ price.close | amountFormat }}</td>
            <td :class="[{'text-success': isUp}, {'text-danger': isDown}]">
              <span v-if="isUp"><img src="@/assets/la/ico_up.gif"></span>
              <span v-else-if="isDown"><img src="@/assets/la/ico_down.gif"></span>
              {{ price.change | amountFormat }} ({{ price.percent | amountFormat }}%)
            </td>
            <td>{{ price.high | amountFormat }}</td>
            <td>{{ price.low | amountFormat }}</td>
            <td>{{ price.val | amountFormat(0) }}</td>
          </tr>
        </tbody>
      </table>

      <LSXChart :data="chartData" />

      <div class="mt-3">
        <table class="table table-result table-bordered table-sm mb-0">
          <tbody>
            <tr class="alert-primary">
              <td class="text-center" width="30%">{{ date }}</td>
              <td class="text-center" width="15%">ເທິ່ງ</td>
              <td class="text-center" width="20%">{{ three }}</td>
              <td class="text-center" width="15%">ລຸ່ມ</td>
              <td class="text-center" width="20%">{{ two }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Footer />
    </div>
  </div>
</template>
<script>
import moment from '@/moment'
import LaService from '@/services/laService'

import Footer from './la-components/Footer'
import LSXChart from './la-components/LSXChart'

export default {
  name: 'LAIndex',
  components: {
    Footer,
    LSXChart
  },
  data() {
    return {
      interval: null,
      count: 0,
      today: moment().format("YYYY-MM-DD"),
      price: {
        available: false,
        time: null,
        close: 0,
        change: 0,
        percent: 0,
        high: 0,
        low: 0,
        vol: 0,
        val: 0,
        open: 0
      },
      histories: []
    }
  },
  computed: {
    date() {
      if(!this.price.time)
        return ''

      const date = moment(this.price.time).format('YYYY/MM/DD')
      return date
    },
    datetime() {
      if(!this.price.time)
        return ''

      const date = moment(this.price.time).format('YYYY/MM/DD')
      const time = moment(this.price.time).format('HH:mm:ss')

      if(time === '16:00:00') {
        return `${date} Closing`
      }else{
        return `${date} ${time}`
      }
    },
    isUp() {
      return this.price.open < this.price.close
    },
    isDown() {
      return this.price.open > this.price.close
    },
    chartData() {
      const histories = this.histories.map((data)=>{
        return {
          x: moment(data.date).format('DD/MM'),
          y: [data.price.open, data.price.high, data.price.low, data.price.close]
        }
      })

      const priceDate = moment(this.price?.time).format('YYYY-MM-DD')
      if(this.histories?.[0]?.date !== priceDate) {
        const todayChart = [{
          x: moment(this.price.time).format('DD/MM'),
          y: [this.price.open, this.price.high, this.price.low, this.price.close]
        }]

        return todayChart.concat(histories)
      }else{
        return histories
      }
    },
    three() {
      if(!this.price.time)
        return ''

      return this.price?.award?.three || ''
    },
    two() {
      if(!this.price.time)
        return ''

      return this.price?.award?.two || ''
    }
  },
  methods: {
    loadData() {
      LaService.getPrice()
      .then((response)=>{
        if(response.status === 'success') {
          this.price = response.data
          // this.$store.dispatch('updatePrice', this.price)
        }
      })
    },
    loadHistories() {
      LaService.getHistories()
      .then((response)=>{
        if(response.status === 'success') {
          this.histories = response.data
        }
      })
    }
  },
  created() {
    this.loadData()
    this.loadHistories()

    this.interval = setInterval(()=>{
      this.count+=1
      this.loadData()
    }, 35000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>
<style lang="scss" scoped>
#la-index {
  padding-bottom: 10px;

  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }

  .header-after {
    margin-top: -4px;
    border-top: 4px solid #A00024;
  }

  .index_summary {
    padding: 30px;
    background-color: #E9F1F2;

    table.summary {
      thead {
        background-color: #F8F8F8;
        border-top: 2px solid #396DB4;

        tr {
          th {
            // font-size: 75%;
            text-align: center;
            padding: 5px 0;
            vertical-align: middle;
            color: #396DB4;
          }
        }
      }

      tbody {
        tr {
          td {
            // font-size: 80%;
            text-align: center;
            background-color: #FFFFFF;
          }
        }
      }
    }

    .table-result, .table-result td, .table-result th {
      border: 1px solid #004085;
    }
  }
}

@media (max-width: 576px) {
  #la-index {
    .index_summary {
      padding: 10px;

      table.summary {
        thead {
          tr {
            th {
              font-size: 75%;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 80%;
            }
          }
        }
      }
    }
  }
}
</style>
