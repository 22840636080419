<template>
  <div class="de-index">
    <div class="header">
      <img src="@/assets/de-header.png">
    </div>
    <div class="de-content">
      <div class="top">
        <div class="left">
          <div class="logo">
            <h1>Market<span>Watch</span></h1>
          </div>
        </div>
        <div class="nav-menu">
          <ul>
            <li><a>Latest</a></li>
            <li class="m2"><a>Coronavirus</a></li>
            <li><a>Watchlist</a></li>
            <li><a>Markets</a></li>
            <li class="m2"><a>Investing</a></li>
            <li class="m1"><a>Barron's</a></li>
            <li class="m1"><a>Personal Finance</a></li>
            <li class="m1"><a>Economy</a></li>
            <li><a>More <i class="fas fa-caret-down"></i></a></li>
          </ul>
        </div>
        <div class="btn-menu">
          <i class="fas fa-bars"></i>
        </div>
      </div>
      <div class="middle">
        <div class="title">
          <h2>DAX PERFORMANCE-INDEX</h2>
        </div>
        <div class="chart-box">
          <div class="prices">
            <div class="price-group">
              <div class="status">
                <span :class="{'mclose':marketActive==='close', 'mopen':marketActive==='open'}">
                  <i class="fas" :class="{'fa-lock':marketActive==='close', 'fa-sun':marketActive==='open'}"></i> {{marketActive==='close' ? 'CLOSED' : 'OPEN'}}
                </span>
              </div>
              <div class="price">
                <h2>{{ price }}</h2>
              </div>
              <div class="price-changed" :class="{'down':marketStatus==='down', 'up':marketStatus==='up'}">
                <i class="fas" :class="{'fa-caret-down':marketStatus==='down', 'fa-caret-up':marketStatus==='up'}"></i>
                <span>{{ change }}</span>
                <span>{{ percent }}%</span>
              </div>
            </div>
            <div class="price-time">
              <span>Last Updated: {{ currentTime }} CEDT</span>
              <small>Delayed quote</small>

              <div class="price-label">
                <label>PREVIOUS CLOSE</label>
                <span>{{ prevClose }}</span>
              </div>
              <div class="price-label">
                <label>OPEN</label>
                <span>{{ open }}</span>
              </div>
              <div class="price-label">
                <label>DAY RANGE</label>
                <span>{{ dayLow }} - {{ dayHigh }}</span>
              </div>
              <div class="price-label">
                <label>52 WEEK RANGE</label>
                <span>{{ yearLowest }} - {{ yearHighest }}</span>
              </div>

            </div>
          </div>
          <div class="chart-plot">
            <apexchart width="100%" height="300" ref="deChart" :options="options" :series="series"></apexchart>

            <div class="today-result">
              <table class="table mb-0">
                <tbody>
                  <tr>
                    <td>closed</td>
                    <td>top</td>
                    <td>{{ ResultThree }}</td>
                    <td>bottom</td>
                    <td>{{ ResultTwo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="dax-profile">
        <h2>DAX Information</h2>
        <p>The German stock index DAX 30 (GER30) was introduced under "DAX" on July, 1st in 1988 by the Frankfurt Stock Exchange. It consists of the 30 largest companies listed on the Frankfurt Stock Exchange based on the market capitalization and liquidity. The trading hours for the Frankfurt Stock Exchange take place from 9:00 a.m. to 5:30 p.m. CET. The DAX30 is usually reported as a performance index, which means that the dividends of the companies are reinvested.</p>
      </div>
    </div>

    <div class="de-footer">
      <div class="footer">
      </div>
    </div>
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'
import VueApexCharts from 'vue-apexcharts'
import moment from '@/moment'

export default {
  name: 'DEIndex',
  components: {
    'apexchart': VueApexCharts
  },
  data() {
    return {
      lottoDate: null,
      Prices: [],
      diffMins: 0,
      StartTime: null,
      EndTime: null,
      yearHighest: null,
      yearLowest: null,
      todayResult: null,
      options: {
        chart: {
          id: 'gb-index-chart',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          floating: false
        },
        subtitle: {
          text: '',
          floating: false
        },
        stroke: {
          curve: 'straight', // smooth | straight
          width: 1
        },
        xaxis: {
          //type: 'datetime',
          type: 'category',
          labels: {
            show: true,
            rotate: 0,
            offsetX: 0,
            offsetY: 0,
            style: {
              colors: '#666'
            },
            formatter: (value)=>{
              switch(value) {
                case '10:00':
                  return '10am'
                case '11:00':
                  return '11am'
                case '12:00':
                  return '12pm'
                case '13:00':
                  return '1pm'
                case '14:00':
                  return '2pm'
                case '15:00':
                  return '3pm'
                case '16:00':
                  return '4pm'
                case '17:00':
                  return '5pm'
                case '18:00':
                  return '6pm'
                default:
                  return ''
              }
            }
          },
          axisTicks: {
            show: false,
            color: '#999'
          }
        },
        yaxis: {
          opposite: true,
          labels: {
            show: true,
            style: {
              colors: '#666'
            },
            formatter: function(val) {
              return val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          },
          axisTicks: {
            show: false,
            color: '#999',
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 3,
        },
        colors: ['#3677a8'],
        grid: {
          show: true,
          borderColor: '#eee',
          position: 'back',
          yaxis: {
            lines: {
              show: true
            }
          },
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        }
      },
      series: [
        {
          name: 'Price',
          type: 'area',
          data: []
        }
      ],
    }
  },
  computed: {
    lastPrice() {
      return this.Prices[this.Prices.length - 1]
    },
    date() {
      if(this.lastPrice) {
        return moment(this.lottoDate).format("MM/DD/YYYY")
      }else{
        return ''
      }
    },
    time() {
      if(this.lastPrice) {
        return this.lastPrice.time
      }else{
        return ''
      }
    },
    price() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.price)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    percent() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? '+'+this.lastPrice.per : this.lastPrice.per;
      }else{
        return ''
      }
    },
    change() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? '+'+this.lastPrice.diff : this.lastPrice.diff;
      }else{
        return ''
      }
    },
    currentTime() {
      if(this.lastPrice) {
        return moment(this.lastPrice.datetime).format("MMM DD, YYYY h:mm a")
      }else{
        return ''
      }
    },
    marketActive() {
      if(this.lastPrice) {
        if( moment().add(this.diffMins, 'm').isBetween(moment(`${this.lottoDate} ${this.StartTime}`), moment(`${this.lottoDate} ${this.EndTime}`).add(10, 'm'))) {
          return 'open'
        }else{
          return 'close'
        }
      }
      return 'close'
    },
    marketStatus() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? 'up' : 'down';
      }else{
        return ''
      }
    },
    prevClose() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.prevClose)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    open() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.open)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    dayHigh() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.high)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    dayLow() {
      if(this.lastPrice) {
        let price = parseFloat(this.lastPrice.low)
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    ResultThree() {
      if(this.todayResult) {
        let split = parseFloat(this.todayResult.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    ResultTwo() {
      if(this.todayResult) {
        let split = parseFloat(this.todayResult.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    }
  },
  methods: {
    updateChart() {
      //this.$axios.$get('chart/de?t='+new Date().getTime())
      LottoService
        .getDeData()
        .then((response)=>{
          //console.log(response)
          if(response.status === 'success') {
            const data = response.data

            this.diffMins = data.diffMins
            this.lottoDate = data.date
            this.Prices = data.prices
            this.StartTime = data.times[0]
            this.EndTime = data.times[data.times.length - 1]
            this.yearHighest = parseFloat(data.yearHighest).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.yearLowest = parseFloat(data.yearLowest).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            let prevPriceOpt = null
            this.$refs.deChart.updateSeries([{
              name: 'Price',
              data: data.times.map((label)=>{

                let priceOpt = data.prices.find((p)=>{
                  return p.time === label
                })

                let thTimeLabel = label //moment(`${data.date} ${label}`).format("HH:mm")
                if(priceOpt) {
                  prevPriceOpt = priceOpt
                  return {
                    x: thTimeLabel,
                    y: parseFloat(priceOpt.price)
                  }
                }else
                if(moment().add(this.diffMins, 'm').isAfter(moment(`${data.date} ${label}`))){
                  return {
                    x: thTimeLabel,
                    y: parseFloat(prevPriceOpt.price)
                  }
                }else{
                  return {
                    x: thTimeLabel,
                    y: null
                  }
                }

              })
            }])

            // หาผลของวันนี้
            let r2 = this.Prices.find((p)=>{
              return p.time === '18:50' && p.available === true
            })
            if(r2 !== undefined) {
              this.todayResult = r2
            }
          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {
      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().add(this.diffMins, 'm').isBetween(moment(`${this.lottoDate} ${this.StartTime}`), moment(`${this.lottoDate} ${this.EndTime}`).add(10, 'm')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log('reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }
    }
  },
  mounted() {
    this.updateChart()
  }
}
</script>
<style lang="scss" scoped>
.de-index {
  margin-bottom: 20px;
  background-color: #fff;
  color: #333;

  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }

  .de-content {
    margin-top: -4px;

    .top {
      background-color: #343434;
      display: flex;
      align-items: center;

      .left {
        display: flex;

        .logo {
          padding: 8px 15px;
          h1 {
            font-size: 24px;
            font-weight: bold;
            font-style: italic;
            color: #fff;
            margin-bottom: 0;
            span {
              color: #00ac4e;
            }
          }
        }
      }
      .nav-menu {
        font-size: 15px;

        ul {
          display: flex;
          margin: 0;
          padding: 0;
          color: #fff;

          li {
            list-style: none;
            cursor: pointer;
            font-weight: 500;
            padding: 0 10px;
          }
        }
      }

      .btn-menu {
        display: none;
      }
    }
    .middle {
      padding: 15px;

      .title {
        border-bottom: 2px solid #2e2e2e;
        h2 {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 0;
        }
      }

      .chart-box {
        display: flex;

        .prices {
          padding: 10px 0;

          .price-group {
            width: 250px;

            div {
              text-align: right;
              line-height: 1.2;
            }

            .status {
              .mclose {
                .fas {
                  color: #666;
                  margin-right: 5px;
                }
              }
              .mopen {
                .fas {
                  color: #fcb839;
                  margin-right: 5px;
                }
              }
            }

            .price {
              h2 {
                font-weight: bold;
                font-size: 45px;
                padding: 0;
                margin: 0;
                line-height: 1;
              }
            }

            .price-changed {
              font-size: 16px;
              font-weight: bold;
              margin-top: 5px;

              &.down {
                color: #b51a28;
              }
              &.up {
                color: #3e9e3e;
              }

              span {
                margin-right: 5px;
              }
            }
          }

          .price-time {
            margin-top: 15px;

            span {
              display: block;
              width: 100%;
              text-align: right;
              font-size: 12px;
              color: #999;
            }
            small {
              margin-top: 2px;
              display: block;
              width: 100%;
              color: #aaa;
              text-align: right;
              padding-bottom: 25px;
            }
            small:before {
              content: " - "
            }

            .price-label {
              margin-top: 10px;
              label {
                display: block;
                width: 100%;
                margin-bottom: 2px;
                padding-bottom: 2px;
                color: #6a6a6a;
                border-bottom: 1px solid #e1e1e1;
                font-size: 11px;
              }
              span {
                font-size: 14px;
                font-weight: bold;
                text-align: right;
                color: #333;
              }
            }
          }

        }

        .chart-plot {
          width: 100%;
          padding-left: 20px;
          min-height: 250px;

          .today-result {
            .table {
              tr {
                td {
                  border: 1px solid #333;
                  padding: 5px;
                  width: 20%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    .dax-profile {
      padding: 10px;
      h2 {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  .de-footer {
    margin-bottom: 20px;
    padding: 0 15px 15px;

    .footer {
      border-bottom: 8px solid #2e2e2e;
      background-color: #fff;
      text-align: center;
      padding: 5px 0;
      font-weight: bold;
    }
  }
}

@media (max-width: 991px) {
  .de-index {
    .de-content {
      .top {
        .nav-menu {
          .m1 {
            display: none;
          }
        }
      }
      .middle {
        .chart-box {
          flex-direction: column;

          .prices {
            .price-group {
              width: 100%;

              div {
                text-align: left;
              }
            }
          }

          .chart-plot {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .de-index {
    .de-content {
      .top {
        .nav-menu {
          .m2 {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .de-index {
    .de-content {
      .top {
        justify-content: space-between;
        .nav-menu {
          display: none;
        }
        .btn-menu {
          display: block;
          color: #eee;
          margin-right: 15px;
          font-size: 22px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .de-index {
    .de-content {
      .top {
        .left {
          .logo {
            h1 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
