import axios from 'axios';

//axios.defaults.baseURL = process.env.VUE_APP_CLOUD_API;

axios.interceptors.request.use(function(config) {
  config.withCredentials = true
  return config;
}, function(error) {
  // Do something with request error
  return Promise.reject(error);
});

export default axios
