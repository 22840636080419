<template>
  <div>
    <div class="footer">
      <div class="row no-gutters">
        <div class="cols col-md-6 l">
          <div class="f-logo">
            <img src="@/assets/la/lsxvip.png">
          </div>
          <div class="f-logo-text">
            <div class="la">ຕະຫຼາດຫຼັກຊັບລາວ</div>
            <div class="en">LAO SECURITIES EXCHANGE</div>
          </div>
        </div>
        <div class="cols col-md-6 r">
          <strong>Copyright @{{year}}. <a href="https://vnindexvip.com/" target="_blank" style="color: #01579B;" class="mr-2">www.lsxvip.com</a></strong> (LAO Securities eXchange VIP). All rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "@/moment"

export default {
  computed: {
    year() {
      return moment().format("YYYY")
    }
  }
}
</script>
<style lang="scss">
.footer {
  padding: 0px;
  font-size: 85%;
  border-radius: 5px;
  margin: 15px 5px 0 5px;

  .cols.l {
    display: flex;

    .f-logo {
      margin-right: 10px;

      img {
        height: 35px;
      }
    }

    .f-logo-text {
      line-height: 1.3;
      color: #CC3B44;
      font-weight: bold;

      .la {
        font-size: 110%;
      }

      .en {
        font-size: 80%;
      }
    }
  }

  .cols.r {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
