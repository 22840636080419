<template>
  <div id="twindex">
    <div class="header">
      <img src="@/assets/tw-header.png">
    </div>
    <div class="content">
      <div class="index-head">Index Charts</div>
      <div class="index-cont">
        <div class="row m-0">
          <div class="col-3 col-left p-0">
            <ul>
              <li class="taiex active">TAIEX</li>
              <li class="info">Main Index Info.</li>
              <li class="tw50">
                <strong>FTSE TWSE Taiwan 50 Index</strong><br>
                <span :class="[isTw50Up?'up':'down']">
                  <span class="p">{{ dispTw50('price') }}</span> &nbsp;
                  <span class="c">{{ dispTw50('diff') }} ({{ dispTw50('per') }}%)</span><br>
                  {{ tw50Date }}
                </span>
              </li>
              <li class="cg100">
                <strong>TWSE CG 100 Index</strong><br>
                <span :class="[isCg100Up?'up':'down']">
                  <span class="p">{{ dispCg100('price') }}</span> &nbsp;
                  <span class="c">{{ dispCg100('diff') }} ({{ dispCg100('per') }}%)</span><br>
                  {{ cg100Date }}
                </span>
              </li>
            </ul>
          </div>
          <div class="col-9 col-right">
            <div class="tabs">
              <ul>
                <li class="active">Real Time</li>
                <li>Week</li>
                <li>Month</li>
                <li>Quarter</li>
                <li>Year</li>
              </ul>
            </div>
            <div class="chart">
              <apexchart width="100%" height="180" ref="twChart" :options="options" :series="series"></apexchart>
            </div>
            <div class="chart-info text-center">
              <label class="t">{{ taiexDate }}</label>
              <label class="TWSE_TXT1">TAIEX:</label>
              <label class="i">{{ dispTaiex('price') }}</label>
              <label class="d" :class="[isTaiexUp?'up':'down']">{{isTaiexUp?'+':''}}{{ dispTaiex('diff') }}</label>
              <label class="p" :class="[isTaiexUp?'up':'down']">{{isTaiexUp?'+':''}}{{ dispTaiex('per') }}%</label>
              <label class="VALUE_TXT">Value</label>
              <label class="tv ml-1">{{ dispTaiex('volume') }} (100 million NTD)</label>
              <br>
              <label class="note" style="position:relative; top:5px;">Odd-lot, Block, Off-hour, Auction and Tender Offer trading are not included in Trade Volume.</label>
            </div>

            <div class="today-result">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>closed</td>
                    <td>top</td>
                    <td>{{ r2ResultThree }}</td>
                    <td>bottom</td>
                    <td>{{ r2ResultTwo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <div class="text-center mt-2">
        <a href="https://tsecvipindex.com/" target="_blank" style="font-size: 110%">www.tsecvipindex.com</a>
      </div>
    </div>
    <div class="tw-final-footer"></div>
  </div>
</template>
<script>
import moment from '@/moment.js'
import LottoService from '@/services/lottoService'
import VueApexCharts from 'vue-apexcharts'

const date = moment().format("YYYY-MM-DD")
export default {
  name: 'TWIndex',
  components: {
    'apexchart': VueApexCharts
  },
  data() {
    return {
      Date: null,
      Prices: [],
      labelTimes: [],
      options: {
        chart: {
          id: 'cn-index-chart',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          floating: true
        },
        subtitle: {
          text: '',
          floating: true
        },
        stroke: {
          curve: 'straight', // smooth | straight
          width: 1
        },
        xaxis: {
          //type: 'datetime',
          type: 'category',
          labels: {
            show: true,
            rotate: 0,
            offsetX: 0,
            offsetY: -5,
            style: {
              colors: '#000'
            },
            //format: 'HH:mm',
            //datetimeUTC: false,
            formatter: (value/*, timestamp, opts*/)=>{
              switch(value) {
                case '09:00':
                  return '09:00'
                case '10:00':
                  return '10:00'
                case '11:00':
                  return '11:00'
                case '12:00':
                  return '12:00'
                case '13:00':
                  return '13:00'
                default:
                  return ''
              }
            }
          },
          axisTicks: {
            show: false,
            color: '#000'
          }
        },
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            style: {
              colors: '#000'
            },
            formatter: function(val) {
              return val//.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          },
          axisTicks: {
            show: false,
            color: '#000',
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 4,
        },
        colors: ['#644EFA'],
        grid: {
          show: true,
          borderColor: '#333',
          position: 'front',
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        }
      },
      series: [
        {
          name: 'Price',
          type: 'area',
          data: []
        }
      ],
      StartTime: null,
      EndTime: null,
      cLabels: null,
      todayResult: {
        r2: null
      }
    }
  },
  computed: {
    lastPrice() {
      return this.Prices[this.Prices.length - 1]
    },
    taiexDate() {
      if(this.Prices) {
        return moment(this.Date).format("YYYY/MM/DD")
      }else{
        return null
      }
    },
    isTaiexUp() {
      if(this.lastPrice) {
        if(parseFloat(this.lastPrice.diff) >= 0) {
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    },

    tw50Date() {
      if(this.lastPrice) {
        return moment(`${this.Date} ${this.lastPrice.time}`).format("MMM DD, YYYY HH:mm")
      }else{
        return null
      }
    },
    isTw50Up() {
      if(this.lastPrice) {
        if(this.lastPrice.tw50.diff >= 0) {
          return true
        }else{
          return false
        }
      }else{
        return true
      }
    },

    cg100Date() {
      if(this.lastPrice) {
        return moment(`${this.Date} ${this.lastPrice.time}`).format("MMM DD, YYYY HH:mm")
      }else{
        return null
      }
    },
    isCg100Up() {
      if(this.lastPrice) {
        if(this.lastPrice.cg100.diff >= 0) {
          return true
        }else{
          return false
        }
      }else{
        return true
      }
    },
    r2ResultThree() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultTwo() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    }
  },
  methods: {
    dispTaiex(key) {
      if(this.lastPrice) {
        return Number(this.lastPrice[key]) === this.lastPrice[key] ? this.lastPrice[key].toFixed(2) : this.lastPrice[key]
      }else{
        return ''
      }
    },
    dispTw50(key) {
      if(this.lastPrice) {
        return Number(this.lastPrice.tw50[key]) === this.lastPrice.tw50[key] ? this.lastPrice.tw50[key].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.lastPrice.tw50[key].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    dispCg100(key) {
      if(this.lastPrice) {
        return Number(this.lastPrice.cg100[key]) === this.lastPrice.cg100[key] ? this.lastPrice.cg100[key].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.lastPrice.cg100[key].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    updateChart() {
      LottoService
        .getTwData()
        .then((response)=>{
          //console.log('response', response)
          if(response.status === 'success') {

            this.Date = response.data.date
            this.Prices = response.data.prices
            this.labelTimes = response.data.tLabels
            this.StartTime = response.data.tLabels[0]
            this.EndTime = response.data.tLabels[response.data.tLabels.length - 1]
            this.cLabels = response.data.labels

            const self = this
            this.$refs.twChart.updateOptions({
              ...this.options,
              xaxis: {
                ...this.options.xaxis,
                labels: {
                  ...this.options.xaxis.labels,
                  formatter: (value)=>{
                    if(self.cLabels[value])
                      return self.cLabels[value]
                    else
                      return ''
                  }
                }
              }
            })

            let prevPrice = null
            this.$refs.twChart.updateSeries([{
              name: 'Price',
              data: this.labelTimes.map((label)=>{

                let price = response.data.prices.find((p)=>{
                  return p.time === label
                })

                if(price) {
                  prevPrice = parseFloat(price.price)
                  return {
                    x: label,
                    y: parseFloat(price.price)
                  }
                }else
                if(moment().isAfter(moment(`${date} ${label}`))){
                  return {
                    x: label,
                    y: prevPrice
                  }
                }else{
                  return {
                    x: label,
                    y: null
                  }
                }

              })
            }])

            // หาผลของวันนี้
            let r2 = this.Prices.find((p)=>{
              return p.time === '12:35' && p.available === true
            })
            if(r2 !== undefined) {
              this.todayResult.r2 = r2
            }

          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {
      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().add(60, 'm').isBetween(moment(`${this.Date} ${this.StartTime}`), moment(`${this.Date} ${this.EndTime}`).add(15, 'm')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log('reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }
    }
  },
  mounted() {
    this.updateChart()
  }
}
</script>
<style lang="scss" scoped>
#twindex {
  margin-bottom: 30px;

  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }

  .content {
    margin-top: -4px;
    border-top: 4px solid #003685;
    background-color: #F0F0F0;
    padding: 15px 15px 10px 15px;

    .index-head {
      text-align: center;
      background-color: #FFF;
      font-size: 18px;
      font-weight: 700;
      padding: 5px;
      border-bottom: 2px solid #a6caff;
    }
    .index-cont {
      background-color: #FFF;
      padding: 15px;
      .col-left {
        ul {
          padding: 0;
          li {
            list-style: none;
            border-bottom: solid 1px #003685;
            padding-left: 5px;
            cursor: pointer;

            &.active {
              background-color: #cde1ff;
            }
            &.taiex {
              display: flex;
              vertical-align: middle;
              align-items: center;
              height: 50px;
              font-weight: bold;
            }
            &.info {
              display: flex;
              vertical-align: middle;
              align-items: center;
              height: 50px;
              font-weight: bold;
            }
            &.tw50 {
              padding: 10px 5px;

              .up {
                .p {
                  color: #090;
                }
                .c {
                  color: #090;
                }
                .c::before {
                  content: '▲';
                }
              }

              .down {
                .p {
                  color: red;
                }
                .c {
                  color: red;
                }
                .c::before {
                  content: '▼';
                }
              }
            }
            &.cg100 {
              padding: 10px 5px;

              .up {
                .p {
                  color: #090;
                }
                .c {
                  color: #090;
                }
                .c::before {
                  content: '▲';
                }
              }

              .down {
                .p {
                  color: red;
                }
                .c {
                  color: red;
                }
                .c::before {
                  content: '▼';
                }
              }
            }
          }
        }
      }
      .col-right {
        label {
          margin-bottom: 0;
        }
        .tabs {
          ul {
            padding: 0;
            margin-top: 0;
            flex-direction: row-reverse;
            margin-bottom: 10px;
            border-bottom: 0;
            display: flex;
            li:first-child {
              border-radius: 0 4px 4px 0;
            }
            li:last-child {
              border-radius: 4px 0 0 4px;
            }
            li {
              list-style: none;
              font-weight: normal;
              display: block;
              flex: 1;
              border: 1px solid #d9e8ff !important;
              text-align: center;
              align-items: center;
              line-height: 30px;
              color: #666;
              cursor: pointer;
              &.active {
                background-color: #d9e8ff;
                color: #000;
              }
            }
          }
        }
        .chart {
          height: 180px;
        }
        .chart-info {
          font-size: 12px;
          line-height: 1;
          .t {
            margin-right: 15px;
          }
          .TWSE_TXT1 {
            margin-right: 5px;
          }
          .i, .d, .p {
            margin-right: 15px;
          }
          .up {
            color: #090;
          }
          .down {
            color: red;
          }
        }

        .today-result {
          margin-top: 20px;
          .table {
            margin: 0 auto;
            width: 500px;
            max-width: 100%;
          }
          .table>tbody>tr>td {
            padding: 5px;
            width: 20%;
            text-align: center;
            border: 1px solid #d9e8ff;
            color: #003685;
            font-weight: 500;
          }
        }
      }
    }
  }

  .tw-final-footer {
    margin: 5px 15px;
    height: 8px;
    background-color: #003685;
  }
}

@media (max-width: 991px) {
  #twindex {
    .content {
      .index-cont {
        padding: 0;

        .row {
          &.m-0 {
            flex-direction: column;

            .col-left {
              width: 100%;
              flex: none;
              max-width: none;
            }
            .col-right {
              width: 100%;
              flex: none;
              max-width: none;
              padding-bottom: 15px;

              .today-result {
                .table {
                  width: 100%;
                  max-width: 500px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #twindex {
    .content {
      padding: 0;
    }
  }
}
</style>
