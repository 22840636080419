<template>
  <div id="sgindex">
    <div class="header">
      <img src="@/assets/sg-header.png">
    </div>
    <div class="sg-content">
      <div class="sg-content-header">
        <h2>Singapore Exchange VIP</h2>
      </div>
      <div class="price-chart">
        <div class="title">
          <h2>Prices & Chart</h2>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="sgx-price" :class="[isPriceUp?'sgx-price--up':'sgx-price--down']">
                <div class="sgx-price-value"><i class="fas" :class="[isPriceUp?'fa-caret-up':'fa-caret-down']"></i> {{ price }}</div>
                <div class="sgx-price-change">({{ change }}/{{ percent }}%)</div>
              </div>
              <table class="text-small sgx-product-price-chart-table">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <td>{{ dispDate }}</td>
                  </tr>
                  <tr>
                    <th>Base Value</th>
                    <td>420.000</td>
                  </tr>
                  <tr>
                    <th>Open</th>
                    <td>{{ open }}</td>
                  </tr>
                  <tr>
                    <th>Prev Close</th>
                    <td>{{ prevClose }}</td>
                  </tr>
                  <tr>
                    <th>52 Week High</th>
                    <td>7,810.150</td>
                  </tr>
                  <tr>
                    <th>52 Week Low</th>
                    <td>5,511.040</td>
                  </tr>
                  <tr>
                    <th>Day High</th>
                    <td>{{ high }}</td>
                  </tr>
                  <tr>
                    <th>Day Low</th>
                    <td>{{ low }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-8">
              <div class="sgx-state-tabs">
                <div class="sgx-state-tabs-scroll-wrapper">
                  <div class="sgx-state-tabs-container">
                    <span class="sgx-state-tab sgx-state-tab--active sgx-state-tab--animate-active" title="Day">
                      <span>Day</span>
                    </span>
                    <span class="sgx-state-tab" title="Week">
                      <span>Week</span>
                    </span>
                    <span class="sgx-state-tab " title="Month">
                      <span>Month</span>
                    </span>
                    <span class="sgx-state-tab sgx-state-tab--animate-inactive" title="1 Year">
                      <span>1 Year</span>
                    </span>
                    <span class="sgx-state-tab" title="5 Years">
                      <span>5 Years</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <apexchart width="100%" height="280" ref="cnChart" :options="options" :series="series"></apexchart>
                <div class="today-result mt-3">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>closed</td>
                        <td>top</td>
                        <td>{{ r2ResultThree }}</td>
                        <td>bottom</td>
                        <td>{{ r2ResultTwo }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-right" style="font-size: 12px; color: #5f6062; line-height: 1.3">
                  As at {{updateTime}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="information">
        <div class="title">
          <h2>Product Informationt</h2>
        </div>
        <div class="content">
          The Straits Times Index (STI) is a market capitalisation weighted index that tracks the performance of the top 30 companies listed on SGX VIP.
        </div>
      </div>
      <div class="text-center p-2" style="background-color: #E7F2F3; margin: 10px 15px 0px;">
        <a href="https://sgxvip.com/" target="_blank" style="font-size: 110%">www.sgxvip.com</a>
      </div>
    </div>
    <div class="sg-final-footer"></div>
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'
import moment from '@/moment.js'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'SGIndex',
  components: {
    'apexchart': VueApexCharts
  },
  data() {
    return {
      labelTimes: [],
      options: {
        chart: {
          id: 'sg-index-chart',
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          floating: true
        },
        subtitle: {
          text: '',
          floating: true
        },
        stroke: {
          curve: 'straight', // smooth | straight
          width: 1
        },
        xaxis: {
          //type: 'datetime',
          type: 'category',
          labels: {
            show: true,
            rotate: 0,
            offsetX: 0,
            offsetY: 0,
            style: {
              colors: '#999'
            },
            //format: 'HH:mm',
            //datetimeUTC: false,
            formatter: (value)=>{
              switch(value) {
                case '09:00':
                  return '09:00'
                case '10:00':
                  return '10:00'
                case '12:00':
                  return '12:00'
                case '14:00':
                  return '14:00'
                case '16:00':
                  return '16:00'
                case '18:00':
                  return '18:00'
                default:
                  return ''
              }
            }
          },
          axisTicks: {
            show: false,
            color: '#999'
          }
        },
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            style: {
              colors: '#999'
            },
            formatter: function(val) {
              return (val/1000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'k'
              //return val.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          },
          axisTicks: {
            show: false,
            color: '#999',
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 4,
        },
        colors: ['#0095b5'],
        grid: {
          show: true,
          borderColor: '#CCCCCC',
          position: 'front',
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        }
      },
      series: [
        {
          name: 'Price',
          type: 'area',
          data: []
        }
      ],
      Prices: [],
      StartTime: null,
      EndTime: null,
      cLabels: null,
      lottoDate: null,
      todayResult: {
        r2: null
      }
    }
  },
  computed: {
    lastPrice() {
      return this.Prices[this.Prices.length - 1]
    },
    price() {
      if(this.lastPrice) {
        return this.lastPrice.price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    prevClose() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.prevClose).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    open() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.open).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    high() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.high).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    low() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.low).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return ''
      }
    },
    percent() {
      if(this.lastPrice) {
        return Math.abs(parseFloat(this.lastPrice.per));
      }else{
        return ''
      }
    },
    change() {
      if(this.lastPrice) {
        return parseFloat(this.lastPrice.per) >= 0 ? '+'+this.lastPrice.diff : this.lastPrice.diff;
      }else{
        return ''
      }
    },
    isPriceUp() {
      if(this.lastPrice) {
        let diff = parseFloat(this.lastPrice.diff)
        if(diff > 0) {
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    },
    r2ResultThree() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultTwo() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    dispDate() {
      if(this.lottoDate) {
        return moment(this.lottoDate).format('DD MMM YYYY')
      }else{
        return '-'
      }
    },
    updateTime() {
      if(this.lastPrice) {
        return moment(`${this.lottoDate} ${this.lastPrice.time}`).format('DD MMM YYYY hh:mm A')
      }else{
        return '-'
      }
    }
  },
  methods: {
    updateChart() {
      LottoService
        .getSgData()
        .then((response)=>{
          if(response.status === 'success') {

            this.lottoDate = response.data.date
            this.Prices = response.data.prices
            this.labelTimes = response.data.tLabels

            this.StartTime = response.data.tLabels[0]
            this.EndTime = response.data.tLabels[response.data.tLabels.length - 1]
            this.cLabels = response.data.labels

            const self = this
            this.$refs.cnChart.updateOptions({
              ...this.options,
              xaxis: {
                ...this.options.xaxis,
                labels: {
                  ...this.options.xaxis.labels,
                  formatter: (value)=>{
                    if(self.cLabels[value])
                      return self.cLabels[value]
                    else
                      return ''
                  }
                }
              }
            })

            let prevPrice = null
            this.$refs.cnChart.updateSeries([{
              name: 'Price',
              data: this.labelTimes.map((label)=>{

                let price = response.data.prices.find((p)=>{
                  return p.time === label
                })

                if(price) {
                  prevPrice = price.price
                  return {
                    x: label,
                    y: price.price
                  }
                }else
                if(moment().isAfter(moment(`${response.data.date} ${label}`))){
                  return {
                    x: label,
                    y: prevPrice
                  }
                }else{
                  return {
                    x: label,
                    y: null
                  }
                }

              })
            }])

            // หาผลของวันนี้
            let r2 = this.Prices.find((p)=>{
              return p.time === '18:05' && p.available === true
            })
            if(r2 !== undefined) {
              this.todayResult.r2 = r2
            }

          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {
      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().add(60, 'm').isBetween(moment(`${this.lottoDate} ${this.StartTime}`), moment(`${this.lottoDate} ${this.EndTime}`).add(15, 'm')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log('reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }
    }
  },
  mounted() {
    this.updateChart()
  }
}
</script>
<style lang="scss" scoped>
#sgindex {
  margin-bottom: 30px;
  //padding-bottom: 20px;

  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }

  .sg-content {
    margin-top: -4px;
    border-top: 4px solid #1C204D;

    .sg-content-header {
      background-image: url('../../assets/sg-content-header.jpg');
      background-repeat: no-repeat;
      height: 80px;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      h2 {
        padding-left: 60px;
        color: #0b236b;
        margin-bottom: 0;
        font-weight: 500;
      }
      h2:before {
        content: "";
        position: absolute;
        background: #24d302;
        margin-top: 5px;
        width: 18px;
        height: 18px;
        box-shadow: 7px 5px 0 #091f60;
        margin-left: -40px;
      }
    }
    .price-chart {
      margin-top: 10px;
      margin-bottom: 20px;
      .title {
        display: flex;
        align-items: center;
        margin-left: 15px;
        margin-bottom: 15px;
        h2 {
          color: #0b236b;
          font-size: 24px;
          margin-bottom: 0;
        }
      }
      .title:after {
        content: "";
        flex: 1 1 auto;
        display: block;
        height: 6px;
        background: #e7e9f0;
        margin-left: 20px;
        margin-right: 15px;
        margin-top: 4px;
      }
      .sgx-price {
        .sgx-price-value {
          font-size: 2em;
          line-height: 1.4em;
          font-weight: 400;
        }
        .sgx-price-change {
          font-size: 1.2em;
        }
        &.sgx-price--up {
          .fas {
            color: #24d302;
            font-size: 1.3em;
            line-height: 1.4em;
            vertical-align: middle;
            padding-right: .3em;
          }
          .sgx-price-change {
            color: #24D302;
          }
        }
        &.sgx-price--down {
          .fas {
            color: #FF0000;
            font-size: 1.3em;
            line-height: 1.4em;
            vertical-align: middle;
            padding-right: 0.3em;
          }
          .sgx-price-change {
            color: #FF0000;
          }
        }
      }
      .sgx-product-price-chart-table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        tr {
          background: #fff;
          border-bottom: 1px solid #b6bdd3;
        }

        tr:nth-child(2n) {
          background-color: #f7f7f7;
        }

        th, td {
          padding: 15px 10px;
          vertical-align: middle;
          line-height: 1;
          font-size: 14px;
          color: #212529;
        }

        th {
          text-align: left;
          font-weight: 600;
        }
      }
      .sgx-state-tabs {
        background: #fff;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        height: 2.5rem;
        -webkit-box-shadow: inset 0 -1px 0 0 #b6bdd3;
        box-shadow: inset 0 -1px 0 0 #b6bdd3;
        font-size: 16px;
        -webkit-tap-highlight-color: rgba(0,0,0,0);

        .sgx-state-tabs-scroll-wrapper {
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
          height: 100%;
          position: relative;
          overflow: hidden;
          -webkit-overflow-scrolling: touch;

          .sgx-state-tab, .sgx-state-tabs-container {
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            position: relative;
          }

          .sgx-state-tabs-container {
            height: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-align-items: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
            white-space: nowrap;
            min-width: 100%;
            padding-right: 1px;
          }

          .sgx-state-tab {
            -webkit-flex: 1 0 auto;
            -ms-flex: 1 0 auto;
            flex: 1 0 auto;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            cursor: pointer;
            padding: 0 20px;
            color: #1a5488;
            overflow-y: hidden;
          }
          .sgx-state-tab:first-of-type {
            padding-left: 0;
          }
          .sgx-state-tab:last-of-type {
            padding-right: 0;
          }
          .sgx-state-tab.sgx-state-tab--active {
            font-weight: 700;
            color: #222;
            cursor: default;
          }
          .sgx-state-tab.sgx-state-tab--animate-active:after {
            -webkit-animation: tab-active .3s ease normal forwards;
            animation: tab-active .3s ease normal forwards;
          }
          .sgx-state-tab.sgx-state-tab--active:after {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
          }
          .sgx-state-tab:first-of-type:after {
            left: 0;
          }
          .sgx-state-tab:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 20px;
            right: 20px;
            height: 6px;
            background: #24D302;
            -webkit-transform: translate3d(0,150%,0);
            transform: translate3d(0,150%,0);
          }
        }
      }
      .today-result {
        .table tr td {
          width: 20%;
          text-align: center;
          font-weight: 500;
          padding: 5px;
          border: 1px solid #b6bdd3;
          color: #212529;
        }
      }
    }
    .information {
      margin-top: 40px;
      .title {
        display: flex;
        align-items: center;
        margin-left: 15px;
        h2 {
          color: #0b236b;
          font-size: 24px;
          margin-bottom: 0;
        }
      }
      .title:after {
        content: "";
        flex: 1 1 auto;
        display: block;
        height: 6px;
        background: #e7e9f0;
        margin-left: 20px;
        margin-right: 15px;
        margin-top: 4px;
      }
      .content {
        font-size: 16px;
        margin: 5px 15px 25px;
      }
    }
  }

  .sg-final-footer {
    margin: 0px 15px 5px;
    height: 8px;
    background-color: #29375C;
  }
}

@media (max-width: 768px) {
  #sgindex .sg-content .price-chart .col-md-8 {
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  #sgindex {
    .sg-content {
      .sg-content-header {
        h2 {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
