import { render, staticRenderFns } from "./JPIndex.vue?vue&type=template&id=4eda288b&scoped=true&"
import script from "./JPIndex.vue?vue&type=script&lang=js&"
export * from "./JPIndex.vue?vue&type=script&lang=js&"
import style0 from "./JPIndex.vue?vue&type=style&index=0&id=4eda288b&lang=scss&scoped=true&"
import style1 from "./JPIndex.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eda288b",
  null
  
)

export default component.exports