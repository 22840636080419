import api from "@/services/apiService"

class LaService {

  getPrice() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_LSX_API + 'price')
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }

  getHistories() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_LSX_API + 'history')
        .then(function(response) {
          resolve(response.data)
        })
        .catch(function(error) {
          reject(error)
        });
    });
  }
}

export default new LaService();
