<template>
  <div id="krindex">
    <div class="header">
      <img src="@/assets/kr-header.png">
    </div>
    <div class="content">
      <div class="site-title-context">
        <div class="title-krx">
          Korea Exchange
          <div class="rew">RENEWED</div>
        </div>
        <div class="title-gap"></div>
        <div>KRX TAKES A QUANTUM LEAP TOWARD A WORLD-CLASS MARKET</div>
      </div>
      <div class="nav">
        <ul class="move-button">
          <li><button type="button"><span></span></button></li>
          <li><button type="button"><span class="b2"></span></button></li>
          <li><button type="button"><span class="b3"></span></button></li>
          <li><button type="button"><span class="b4"></span></button></li>
          <li><button type="button"><span class="b5"></span></button></li>
          <li><button type="button"><span class="b6"></span></button></li>
        </ul>
      </div>

      <div class="intro-index-wrap">
        <div class="intro-index">
          <ul>

            <li>
              <span class="index-name">KTOP 30</span><br class="no-mobile">
              <span class="index-price">{{ ktop30Price }}</span><br class="no-mobile">
              <span :class="[isKtop30PriceUp?'index-up':'index-down']">{{isKtop30PriceUp?'▲':'▼'}} {{ktop30Diff}} ({{ktop30Per}})</span>
            </li>

            <li>
              <span class="index-name">KOSPI</span><br class="no-mobile">
              <span class="index-price">{{ dispAbs('kospi', 'price') }}</span><br class="no-mobile">
              <span :class="[isPriceUp('kospi')?'index-up':'index-down']">{{isPriceUp('kospi')?'▲':'▼'}} {{ dispAbs('kospi', 'diff') }} ({{ dispAbs('kospi', 'per') }})</span>
            </li>

            <li>
              <span class="index-name">KOSPI 200</span><br class="no-mobile">
              <span class="index-price">{{ dispAbs('kospi200', 'price') }}</span><br class="no-mobile">
              <span :class="[isPriceUp('kospi200')?'index-up':'index-down']">{{isPriceUp('kospi200')?'▲':'▼'}} {{ dispAbs('kospi200', 'diff') }} ({{ dispAbs('kospi200', 'per') }})</span>
            </li>

            <li>
              <span class="index-name">KOSDAQ</span><br class="no-mobile">
              <span class="index-price">{{ dispAbs('kosdaq', 'price') }}</span><br class="no-mobile">
              <span :class="[isPriceUp('kosdaq')?'index-up':'index-down']">{{isPriceUp('kosdaq')?'▲':'▼'}} {{ dispAbs('kosdaq', 'diff') }} ({{ dispAbs('kosdaq', 'per') }})</span>
            </li>

            <li>
              <span class="index-name">KRX 300</span><br class="no-mobile">
              <span class="index-price">{{ dispAbs('krx300', 'price') }}</span><br class="no-mobile">
              <span :class="[isPriceUp('krx300')?'index-up':'index-down']">{{isPriceUp('krx300')?'▲':'▼'}} {{ dispAbs('krx300', 'diff') }} ({{ dispAbs('krx300', 'per') }})</span>
            </li>

          </ul>
        </div>
        <div class="today-result">
          <div>
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>closed</td>
                  <td>top</td>
                  <td>{{ r2ResultThree }}</td>
                  <td>bottom</td>
                  <td>{{ r2ResultTwo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
    <div class="text-center mt-2">
      <a href="https://ktopvipindex.com/" target="_blank" style="font-size: 110%">www.ktopvipindex.com</a>
    </div>
    <div class="kr-final-footer"></div>
  </div>
</template>
<script>
import moment from '@/moment.js'
import LottoService from '@/services/lottoService'

export default {
  name: 'KRIndex',
  data() {
    return {
      Date: null,
      Prices: null,
      StartTime: null,
      EndTime: null,
      todayResult: {
        r1: null,
        r2: null
      }
    }
  },
  computed: {
    ktop30() {
      if(this.Prices) {
        return {
          price: Math.abs(parseFloat(this.Prices.price)),
          diff: Math.abs(parseFloat(this.Prices.diff)),
          per: Math.abs(parseFloat(this.Prices.per))
        }
      }else{
        return false
      }
    },
    isKtop30PriceUp() {
      if(this.ktop30) {
        return parseFloat(this.ktop30.diff) >= 0
      }else{
        return false
      }
    },
    ktop30Price() {
      if(this.ktop30) {
        return this.ktop30.price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    ktop30Diff() {
      if(this.ktop30) {
        return this.ktop30.diff.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    ktop30Per() {
      if(this.ktop30) {
        return this.ktop30.per.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    r2ResultThree() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.price).toFixed(2).split('')
        let length = split.length
        return `${split[length-4]}${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    },
    r2ResultTwo() {
      if(this.todayResult.r2) {
        let split = parseFloat(this.todayResult.r2.diff).toFixed(2).split('')
        let length = split.length
        return `${split[length-2]}${split[length-1]}`
      }else{
        return '-'
      }
    }
  },
  methods: {
    isPriceUp(index) {
      if(this.Prices) {
        return parseFloat(this.Prices[index].diff) >= 0
      }else{
        return false
      }
    },
    dispAbs(index, filed) {
      if(this.Prices) {
        return Math.abs(parseFloat(this.Prices[index][filed])).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
        return ''
      }
    },
    updateChart() {
      LottoService
        .getKrData()
        .then((response)=>{
          if(response.status === 'success') {
            this.Date = response.data.date
            this.Prices = response.data.prices
            this.StartTime = response.data.tLabels[0]
            this.EndTime = response.data.tLabels[response.data.tLabels.length - 1]
            this.todayResult = response.data.results
          }
        })
        .catch((err)=>{
          console.log(err)
        })
        .finally(()=>{
          this.calReload()
        })
    },
    calReload() {
      // ตรวจสอบช่วงเวลา
      if(this.StartTime === null || this.EndTime === null) {
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else
      if( moment().add(60, 'm').isBetween(moment(`${this.Date} ${this.StartTime}`), moment(`${this.Date} ${this.EndTime}`).add(15, 'm')) ){
        let diffSec = moment(moment().add(1, 'm').format("YYYY-MM-DD HH:mm:00")).diff(moment(), 's')
        diffSec = diffSec <= 0 ? 60 : diffSec
        console.log(this.StartTime, this.EndTime, diffSec)
        setTimeout(()=>{ this.updateChart() }, diffSec*1000);
      }else{
        console.log('reload every 5 minute')
        setTimeout(()=>{ this.updateChart() }, 5*60*1000);
      }
    }
  },
  mounted() {
    this.updateChart()
  }
}
</script>
<style lang="scss" scoped>
#krindex {
  margin-bottom: 30px;

  .header {
    img {
      display: block;
      max-width: 100%;
    }
  }

  .content {
    margin-top: -4px;
    border-top: 4px solid #14AAAC;
    //margin-top: 5px;
    background: url('../../assets/kr-bg.png') no-repeat bottom center;
    min-height: 600px;
    .site-title-context {
      padding: 80px 80px 20px;
      .title-krx {
        color: #333333;
        font-size: 285%;
        .rew {
          font-size: 121%;
          font-weight: bold;
        }
      }
      .title-gap {
        margin: 20px 0 25px 0;
        width: 70px;
        height: 2px;
        background: #0aacbb;
      }
    }
    .nav {
      display: block;
      margin-top: 60px;
      ul {
        //position: relative;
        width: 805px;
        height: 110px;
        bottom: 395px;
        list-style-type: none;
        margin: 0 auto;

        padding: 0;
        li {
          list-style: none;
          display: inline-block;
          position: relative;
          height: 110px;
          width: 110px;
          margin: 0 10px;
          button {
            border: 0;
            background: rgba(255, 255, 255, 0);
            border-radius: 50px;
            width: 100%;
            height: 100%;
            margin: auto;
            padding: 0;
            span {
              display: inline-block;
              width: 110px;
              height: 110px;
              background-image: url(../../assets/btn-globallink.png);
              background-repeat: no-repeat;
              margin: auto;
              background-position-x: 0;
              background-position-y: 0;

              &.b2 {
                background-position-y: -150px;
              }
              &.b3 {
                background-position-y: -300px;
              }
              &.b4 {
                background-position-y: -450px;
              }
              &.b5 {
                background-position-y: -600px;
              }
              &.b6 {
                background-position-y: -750px;
              }
            }
          }
        }
      }
    }
    .intro-index-wrap {
      padding-top: 30px;
      padding-bottom: 20px;
      .intro-index {
        position: relative;
        width: 980px;
        height: 100%;
        background: url(../../assets/bg-index.png);
        margin: auto;
        ul {
          padding: 0;
          display: table;
          width: 100%;
          height: 100%;
          margin-bottom: 0;
          li {
            list-style: none;
            display: table-cell;
            width: 20%;
            text-align: center;
            box-sizing: border-box;
            padding: 20px 0;
            span {
              display: inline-block;
              line-height: 1;
            }
            .index-name {
              font-size: 100%;
              color: #ffffff;
              margin-bottom: 10px;
            }
            .index-price {
              font-size: 178%;
              color: #ffffff;
            }
            .index-up {
              font-size: 100%;
              color: #ffa800;
            }
            .index-down {
              font-size: 100%;
              color: #00ccff;
            }
          }
        }
      }
      .today-result {
        width: 100%;
        max-width: 980px;
        margin: 0 auto;
        padding: 10px 10px 10px 10px;
        background-color: #314D60;
        border-top: 1px solid #213442;
        //background: url('~assets/bg-index.png') center center;
        & div {
          background-color: #14AAAC;
          border-radius: 6px;
          padding: 5px;
        }
        .table {
          margin-bottom: 0px;
          border: 0;
        }
        .table>tbody>tr>td {
          width: 20%;
          padding: 5px;
          text-align: center;
          color: #FFFFFF;
        }
        .table-bordered th, .table-bordered td {
          border: 0px solid #FFFFFF;
        }
      }
    }
  }

  .kr-final-footer {
    margin: 5px 15px;
    height: 8px;
    background-color: #14AAAC;
  }
}

@media (max-width: 1199px) {
  #krindex {
    .content {
      .intro-index-wrap {
        .intro-index {
          background-size: 100% 100%;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #krindex {
    .content {
      .nav {
        display: flex;
        flex-wrap: wrap;
        ul {
          width: auto;
          height: auto;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #krindex {
    .content {
      .site-title-context {
        padding: 20px;
      }
      .intro-index-wrap {
        .intro-index {
          background-image: none;
          background-color: #425c62;

          ul {
            padding: 0;
            display: table;
            width: 100%;
            height: 100%;
            margin-bottom: 0;

            li {
              display: table;
              width: 100%;
              height: 32px;
              box-sizing: border-box;
              padding: 5px 12px;
              border-top: 1px solid #354a58;

              .no-mobile {
                display: none;
              }

              .index-name {
                margin-bottom: 0;
                width: 30%;
                text-align: left;
              }
              .index-price {
                font-size: 100%;
                width: 32%;
                text-align: right;
              }
              .index-up,.index-down {
                text-align: right;
                width: 38%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
